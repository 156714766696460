<template>
    <MainLayout>
        <LoaderPage v-if="loadingPage" />
        <HeaderBreadcrumb
            :items="items"
            :page="page"
        />
        <div class="container">
            <br><br>
            <div class="row min-h-85">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            Configuração da Aeração:
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col form-group">
                                    <div class="form-group">
                                        <label class="form-label col-form-label" for="habilita_horario_ponta">Horário de Ponta:</label>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" name="habilita_horario_ponta" id="habilita_horario_ponta" value="ativo" v-model="parametros_configuracoes['horario_ponta']">Habilitado
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" name="habilita_horario_ponta" id="habilita_horario_ponta" value="inativo" v-model="parametros_configuracoes['horario_ponta']">Desabilitado
                                            </label>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                    <div class="col-sm-auto form-group">
                                        <label class="col form-label col-form-label" for="horario_ponta_inicial">
                                        Inicio:
                                        </label>
                                        <input class="col form-control" type="time" v-model="parametros_configuracoes['horario_ponta_inicio']">

                                    </div>
                                    <br>
                                    <div class="col-sm-auto form-group">
                                        <label class="col form-label col-form-label" for="horario_ponta_final">
                                        Término:
                                        </label>
                                        <input class="col form-control" type="time" v-model="parametros_configuracoes['horario_ponta_termino']">
                                    </div>
                                    </div>
                                    <br>
                                    
                                </div>
                                <div class="col form-group">
                                    <div class="form-group">
                                        <label class="form-label col-form-label" for="habilita_aeracao_chuva">Aeração quando chovendo:</label>
                                        <br>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['aeracao_chuva']" value="ativo">Aerar com Chuva
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['aeracao_chuva']" value="inativo" >Aerar somente sem Chuva
                                            </label>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <label class="form-label col-form-label" for="habilita_aeracao_termometria">Aeração na termometria:</label>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['aeracao_termometria']" value="ativo">Aerar
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['aeracao_termometria']" value="inativo">Não Aerar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col form-group">
                                    <div class="form-group">
                                        <label class="form-label col-form-label" for="habilita_intervalo_tempo_chuva">Intervalo pós chuva:</label>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['intervalo_pos_chuva']" value="ativo">Habilitada
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['intervalo_pos_chuva']" value="inativo">Desabilitada
                                            </label>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row form-group">
                                            <div class="col-sm-auto">
                                                <label class="form-label col-form-label" for="intervalo_espera_chuva">
                                                Intervalo de espera pós chuva:
                                                </label>
                                            </div>
                                            <div class="col-sm-4">
                                                <input class="form-control col-form-label" type="number" v-model="parametros_configuracoes['intervalo_espera_pos_chuva']" >
                                            </div>
                                    </div>
                                    <br>
                                    <div class="row form-group">
                                            <div class="col-sm-auto">
                                                <label class="form-label col-form-label" for="tempo_partida_motores">
                                                Intervalo partida de motores:
                                                </label>
                                            </div>
                                            <div class="col-sm-4">
                                                <input class="form-control col-form-label" type="number" v-model="parametros_configuracoes['intervalo_partida_motores']" >
                                            </div>
                                    </div>
                                    <br>
                                </div>
                                <br>
                                <br>
                                <div class="col form-group">
                                    <div class="form-group">
                                        <label class="form-label col-form-label" for="habilita_demanda">Demanda:</label>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['demanda']" value="ativo">Habilitada
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="parametros_configuracoes['demanda']" value="inativo">Desabilitada
                                            </label>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row form-group">
                                        <label class="col-sm-auto col-form-label" for="demanda_kw">
                                        Demanda Kw Aeração:
                                        </label>
                                        <div class="col-sm-4">
                                            <input class="form-control" type="number" v-model="parametros_configuracoes['aeracao_demanda_kw']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row form-group">
                                        <label class="col-sm-auto col-form-label" for="custo_kw">
                                        Custo do Kw/h:
                                        </label>
                                        <div class="col-sm-4">
                                        <input class="form-control" name="custo_kw" id="custo_kw" step="any" type="number" v-model="parametros_configuracoes['aeracao_custo_kw']">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="display-space-1 text-center">
                                    <button class="btn btn-success box-shadow-w" style="margin: 15px;" :disabled="saveParametrosLoading" @click="storeParametrosConfiguracoes"><span v-if="!saveParametrosLoading">Atualizar parâmetros</span> <span v-else>Salvando parametros...</span></button>
                                    <button type="reset" class="btn btn-default box-shadow-w" style="margin: 15px;">Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import HeaderBreadcrumb from '@/components/ui/App/HeaderBreadcrumb.vue';
import LoaderPage from '@/components/ui/LoaderPage.vue';
import { useToast } from 'vue-toastification';

export default {
    components: {
        MainLayout,
        HeaderBreadcrumb,
        LoaderPage
    },

    data() {
        return {
            items: [
                {"descricao": "Winckieel", "url": "/v1/unidades"},
                {"descricao": "Unidade", "url": "/v1/unidades/"+ this.$route.params.unidade},
            ],
            page: "Aeração",
            saveParametrosLoading: false,
            parametros_configuracoes: {
                "horario_ponta_inicio": null,
                "horario_ponta_termino": null,
                "horario_ponta": null,
                "aeracao_chuva": null,
                "aeracao_termometria": null,
                "intervalo_pos_chuva": null,
                "intervalo_espera_pos_chuva": null,
                "intervalo_partida_motores": null,
                "demanda": null,
                "aeracao_demanda_kw": null,
                "aeracao_custo_kw": null
            },
            loadingPage: true
        }
    },

    async mounted() {
        this.toast = useToast();
        this.getParametrosConfiguracoes();
    },

    methods: {
        async getParametrosConfiguracoes() {
            try {
                const response = await this.$store.dispatch('unidade/getParametrosConfiguracoes', this.$route.params.unidade);
                if (response) {
                    this.parametros_configuracoes = response;
                } 
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
                this.toast.error("Erro ao buscar os parâmetros");
            }
            this.loadingPage = false;
        },
        async storeParametrosConfiguracoes() {
            try{
                this.saveParametrosLoading = true;
                const unidade = this.$route.params.unidade;  
                const datas = this.parametros_configuracoes;
                datas['horario_ponta_inicio'] = datas['horario_ponta_inicio'].substring(0, 5);
                datas['horario_ponta_termino'] = datas['horario_ponta_termino'].substring(0, 5);
                const response = await this.$store.dispatch('unidade/storaParametrosConfiguracoes', {'unidadeId': unidade, 'data': datas })
                this.parametros_configuracoes = response;
                this.saveParametrosLoading = false;
                this.toast.success("Parâmetros atualizados com sucesso");
            } catch (error) {
                this.getParametrosConfiguracoes();
                this.saveParametrosLoading = false;
                this.toast.error("Erro ao atualizar parâmetros");
            }
        }
    }
}
</script>