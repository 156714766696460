<template>
	<div class="card text-center header-silo">
		<div @click="navigateToAnteSilo" style="cursor: pointer;">
			<i class="fa-solid fa-circle-chevron-left icon-header"></i>
		</div>
		<div>
			<h4 class="title-relatorio" style="color: #000; font-weight: bold">
				{{ silo.nome }}
			</h4>
			<small>{{ vue_app_name }} v{{ version }} </small>
		</div>
		<div @click="navigateToProxSilo" style="cursor: pointer;">
			<i class="fa-solid fa-circle-chevron-right icon-header"></i>
		</div>
	</div>
</template>

<script>
	import { useRouter } from 'vue-router';
	import { useStore } from 'vuex';
	import { computed } from 'vue';

	export default {
		props: {
			silo: {
				type: Object,
				required: true
			},
			unidadeId: {
				type: String,
				required: true
			}
		},

		setup(props) {
			const router = useRouter();
			const store = useStore();
			const vue_app_name = process.env.VUE_APP_NAME;
			const version = process.env.VUE_APP_VERSION;

			const unidadesSilos = computed(() => store.state.unidades.silos);
			
			const currentIndex = computed(() => {
				return unidadesSilos.value.findIndex(s => s.id === props.silo.id);
			});

			const navigateToAnteSilo = () => {
				const anteIndex = (currentIndex.value - 1 + unidadesSilos.value.length) % unidadesSilos.value.length;
				const anteSiloId = unidadesSilos.value[anteIndex].id;
				router.push(`/v1/unidades/${props.unidadeId}/silos/${anteSiloId}`);
			};

			const navigateToProxSilo = () => {
				const proxIndex = (currentIndex.value + 1) % unidadesSilos.value.length;
				const proxSiloId = unidadesSilos.value[proxIndex].id;
				router.push(`/v1/unidades/${props.unidadeId}/silos/${proxSiloId}`);
			};

			return {
				vue_app_name,
				version,
				navigateToAnteSilo,
				navigateToProxSilo
			};
		}
	};
</script>

<style scoped>
	.header-silo {
		height: 10vh;
		padding: 1em;
		border-radius: 0px !important;
		display: flex;
		align-content: stretch;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
        box-shadow: 0px 8px 20px 0px #d5c0c0;
        background-color: #ffffff !important;
	}

	.icon-header {
		font-size: 1.8rem; /* Ajuste o tamanho conforme necessário */
	}
</style>
