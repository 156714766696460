<template>
    <router-link
        :to="'/v1/unidades/' + unidade.id"
        class="card-row"
    >
        <img :src="getImageUrl(unidade.imagem)" :alt="unidade.nome" class="image-unidade"/>
        <p class="card-text">{{ unidade.nome }}</p>
    </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        unidade: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getImageUrl(imagePath) {
            return `${process.env.VUE_APP_SERVER}/storage/${imagePath}`;
        },
    },
});
</script>

<style scoped>
.card-row {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-decoration: none;
    color: inherit;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-row:hover {
    background-color: #f9f9f9;
}

.image-unidade {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.card-text {
    font-size: 16px;
    color: #333;
    flex: 1;
}
</style>
