<template>
	<MainLayout>
		<EstacaoComponent />
		<HeaderUnidade />
		<section class="padding-1-5 section-silos">
			<div class="card">
				<div class="card-header">
					<div class="headercard">
						<h4 style="margin: 0%">Silos/armazéns</h4>
					</div>
				</div>
				<div class="card-body" id="silos">
					<div class="row row-silos" id="content-silos-view">
                        <CardSilo v-for="silo in silos" :key="silo.id" :silo="silo" />
					</div>
				</div>
			</div>
		</section>
	</MainLayout>
</template>

<script>
import EstacaoComponent from "@/components/ui/App/EstacaoComponent.vue";
import HeaderUnidade from "@/components/ui/App/HeaderUnidade.vue";
import MainLayout from "@/components/layouts/MainLayout.vue";
import CardSilo from "@/components/ui/App/CardSilo.vue";

import "@/assets/css/pages/unidade-page.css";

import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export default {
	components: {
		EstacaoComponent,
		HeaderUnidade,
		MainLayout,
        CardSilo
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const unidade = ref({});
		const silos = ref([]);
		const toast = useToast();


		onMounted(async () => {
			const unidadeId = route.params.unidade;

			try {
				await store.dispatch("unidade/fetchUnidade", unidadeId);
				unidade.value = store.state.unidade.unidade;
			} catch (error) {
				console.error("Erro ao buscar unidade:", error);
				toast.error("Erro ao buscar unidade");
			}

			try {
				await store.dispatch("silos/fetchSilosByUnidade", unidadeId);
				silos.value = store.state.silos.silos;
			} catch (error) {
				console.error("Erro ao buscar silos:", error);
				toast.error("Erro ao buscar silos");
			}
		});

		return {
			unidade,
			silos,
		};
	},
};
</script>

<style scoped>
.padding-1-5 {
	padding: 1.5rem;
}

.section-silos {
    min-height: 90vh;
}

.card {
	margin-bottom: 1.5rem;
}

.row-silos {
	display: flex;
	flex-wrap: wrap;
}

.card-title {
	font-size: 1.25rem;
	font-weight: bold;
}

.card-text {
	font-size: 1rem;
}


</style>
