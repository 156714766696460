<template>
    <section class="row card header-unidade">
        <div class="card-body display-space-between ">
            <h5 style="color: #000000;font-weight: bold; text-transform: uppercase; margin-left: 12px;">{{ unidade.nome }}</h5>
            <h5 style=" margin-right: 12px;">{{ vue_app_name }} v{{ version }}</h5>
        </div>
    </section>
</template>
<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
    setup () {
        const vue_app_name = process.env.VUE_APP_NAME;
        const version = process.env.VUE_APP_VERSION;
        const store = useStore();
        const unidade = ref({});

        watch(() => store.state.unidade.unidade, (newUnidade) => {
				unidade.value = newUnidade;
			}, { immediate: true }
		);

        return {
            vue_app_name,
            version,
            unidade
        }
    }
}
</script>
<style scoped>
.display-space-between {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 8px 20px 0px #d5c0c0;
    background-color: #ffffff !important;
}
</style>