<template>
    <div class="container-fluid container-programa">
        <div class="row">
            <ProgramasComponent :parametros="silo.parametros" ref="programasComponent" />
            <div class="col-sm-12">
                <hr>
            </div>
            <ProgramaCurva :parametros="silo.parametros" />
            <AeracaoAcoes ref="aeracaoAcoes" :programa="silo.programa" :modo_operacao="silo.modo_operacao" :aeracao="silo.bloqueio_aeracao" :produto="silo.produto" />
            <div class="display-space-1 text-center">
                <button class="btn btn-success box-shadow-w" style="margin: 15px;" @click="emitirSalvarParametrizacao" :disabled="saveParametrosLoading"><span v-if="!saveParametrosLoading">Atualizar parâmetros</span> <span v-else>Salvando parametros...</span></button>
                <button type="reset" class="btn btn-default box-shadow-w" style="margin: 15px;">Cancelar</button>
            </div>
        </div>
    </div>
</template>
<script>
import ProgramasComponent from "./Aeracao/ProgramasComponent.vue";
import ProgramaCurva from "./Aeracao/ProgramaCurva.vue";
import AeracaoAcoes from "./Aeracao/AeracaoAcoes.vue";

export default {
    components: {
        ProgramasComponent,
        ProgramaCurva,
        AeracaoAcoes
    },
    props: {
        silo: {
            type: Object,
            default: () => ({
                "programa": {}
            })
        },
        saveParametrosLoading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        emitirSalvarParametrizacao() {
            const parametros = this.$refs.programasComponent.getParametrosProgramas();
            const aeracao = this.$refs.aeracaoAcoes.getAeracaoAcoes();
            this.$emit('salvar-parametros', {parametros, aeracao});
        }
    },

}

</script>