<template>
  <div class="row">
    <div id="chart_termometria" style="width: 100%; height: 300px"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>