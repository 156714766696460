import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from 'vue-toastification';
import '/src/assets/css/vue-toastification.css';
import store from "./store";

// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.css';

// Import Bootstrap JS
import "bootstrap";

createApp(App).use(router).use(store).use(Toast).mount("#app");
