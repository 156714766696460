import axios from "@/axios";

const getDefaultState = () => ({
    leituras: [],
    leitura: {}
});

const state = getDefaultState();

const mutations = {
    SET_LEITURAS(state, leituras) {
      state.leituras = leituras;
    },
    SET_LEITURA(state, leitura) {
        state.leitura = leitura;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
}

const actions = {
    async fetchLeiturasBySilo({ commit }, siloId ) {
        try {
            const response = await axios.get(`/silos/${siloId}/leituras`);
            commit('SET_LEITURAS', response.data);
            return response.data
        } catch (error) {
            console.error("Erro ao buscar leituras do silo:", error);
			throw error;
        }
    },
    async fetchLeituraById({ commit }, body) {
        try {
            const response = await axios.get(`/silos/${body.siloId}/leituras/${body.leituraId}`)
            commit('SET_LEITURA', response.data);
            return response.data
        } catch (error) {
            console.error("Erro ao buscar leitura: ", error)
            throw error;
        }
    },
};

export default {
    namespaced: true,
    actions,
    mutations,
    state
}