<template>
    <section class="div-estacao">
        <div class="card-estacao-inline">
            <div>
                <img
                    src="/images/icons/estacao/sol.png"
                    alt="temperatura"
                    id="imgTemp"
                    style="width: 16px"
                />
                <span class="estacao_temperatura"
                    >{{
                        estacaoData ? estacaoData.temperatura.toFixed(2) : "--"
                    }}°C</span
                >
                | &nbsp;
            </div>
            <div>
                <img
                    src="/images/icons/estacao/umidade.png"
                    alt="umidade"
                    id="imgUmidade"
                    style="width: 16px"
                />
                <span class="estacao_umidade"
                    >{{
                        estacaoData ? estacaoData.umidade.toFixed(2) : "--"
                    }}%</span
                >
                | &nbsp;
            </div>
            <div>
                <img
                    src="/images/icons/estacao/nuvem.png"
                    alt="chuva"
                    id="imgChuva"
                    style="width: 16px"
                />
                <span class="estacao_chuva">{{
                    estacaoData && estacaoData.chuva > 0
                        ? "Chovendo"
                        : "Sem chuva"
                }}</span>
                | &nbsp;
            </div>
            <div>
                <img
                    src="/images/icons/estacao/pluviometro.png"
                    alt="pluv"
                    id="imgPluviometro"
                    style="width: 16px"
                />
                <span class="estacao_pluviometro"
                    >{{
                        estacaoData ? estacaoData.pluviometro.toFixed(2) : "--"
                    }}mm</span
                >
            </div>
        </div>
        <div id="estacao_estacao">
            Estação {{ estacaoData ? estacaoData.estacao : "--" }} -
            {{ estacaoData ? estacaoData.data : "--" }}
        </div>
    </section>
</template>
<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
    name: "EstacaoComponent",
    setup() {
        const store = useStore();
        const route = useRoute();
        const estacaoData = computed(
            () => store.getters["estacao/estacao"]
        );

        onMounted(async () => {
            const unidadeId = route.params.unidade;
            await store.dispatch("estacao/fetchEstacaoData", unidadeId);
        });

        return {
            estacaoData
        };
    },
};
</script>
<style scoped>
.div-estacao {
    background-color: #fff;
    border-radius: 15px;
    height: auto;
    padding: 0.2em;
    align-content: center;
    align-items: center;
    width: auto;
    margin: 2vh;
    color: black;
    font-size: 0.8em;
    font-weight: bolder;
    text-transform: capitalize;
    -webkit-box-shadow: 0px 0px 19px -3px rgba(102, 102, 102, 0.65);
    -moz-box-shadow: 0px 0px 19px -3px rgba(102, 102, 102, 0.65);
    box-shadow: 0px 0px 19px -3px rgba(102, 102, 102, 0.65);
    position: fixed;
    z-index: 1;
    top: 12%;
    right: 0%;
}

.card-estacao-inline {
    padding: 0.5vh;
    display: flex;
    justify-content: space-evenly;
}

#estacao_estacao {
    font-size: 0.7em;
    text-align: center;
}
</style>
