<template>
	<main>
		<SideBar />
		<div class="page">
			<NavBar />
			<slot></slot>
            <FooTer />
		</div>
	</main>
</template>

<script>
import FooTer from "../ui/FooTer.vue";
import NavBar from "../ui/NavBar.vue";
import SideBar from "../ui/SideBar.vue";
import "@/assets/css/app.css";
import "@/assets/css/choices.min.css";
import "@/assets/css/custom.css";
import "@/assets/css/fonts.css";
import "@/assets/css/OverlayScrollbars.min.css";
import "@/assets/css/style-sidebar.css";
import "@/assets/css/style-default.css";

export default {
	name: "MainLayout",
	components: {
		SideBar,
		NavBar,
		FooTer,
	}
};
</script>
<style>
	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link {
		color: #495057;
		background-color: #fff;
		border-color: #dee2e6 #dee2e6 #fff;
	}

	.nav-tabs .nav-link {
		margin-bottom: -1px;
		background: none;
		border: 1px solid transparent;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}

    .page {
        min-height: 102vh;
        padding-bottom: 0em;
    }

    .min-h-85 {
        min-height: 85vh;
    }

	.card {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		border: 0 solid rgba(0, 0, 0, 0.125);
		border-radius: 0;
	}

	.card > hr {
		margin-right: 0;
		margin-left: 0;
	}

	.card > .list-group {
		border-top: inherit;
		border-bottom: inherit;
	}

	.card > .list-group:first-child {
		border-top-width: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.card > .list-group:last-child {
		border-bottom-width: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.card > .card-header + .list-group,
	.card > .list-group + .card-footer {
		border-top: 0;
	}

	.card-body {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 1.5rem 1.5rem;
	}

	.card-title {
		margin-bottom: 1rem;
	}

	.card-subtitle {
		margin-top: -0.5rem;
		margin-bottom: 0;
	}

	.card-text:last-child {
		margin-bottom: 0;
	}

	.card-link + .card-link {
		margin-left: 1.5rem;
	}

	.card-header {
		padding: 1.125rem 1.5rem;
		margin-bottom: 0;
		background-color: #fff !important;
		border-bottom: 0 solid rgba(0, 0, 0, 0.125);
	}

	.card-header:first-child {
		border-radius: 0 0 0 0;
	}

	.card-footer {
		padding: 1.125rem 1.5rem;
		background-color: #fff;
		border-top: 0 solid rgba(0, 0, 0, 0.125);
		border-radius: 15px !important;
	}

	.card-footer:last-child {
		border-radius: 0 0 0 0;
	}

	.card-header-tabs {
		margin-right: -0.75rem;
		margin-bottom: -1.125rem;
		margin-left: -0.75rem;
		border-bottom: 0;
	}

	.card-header-pills {
		margin-right: -0.75rem;
		margin-left: -0.75rem;
	}

	.card-img-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 1rem;
		border-radius: 0;
	}

	.card-img,
	.card-img-top,
	.card-img-bottom {
		width: 100%;
	}

	.card-img,
	.card-img-top {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.card-img,
	.card-img-bottom {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.card-group > .card {
		margin-bottom: 0.75rem;
	}

	@media (min-width: 576px) {
		.card-group {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-flow: row wrap;
			flex-flow: row wrap;
		}
		.card-group > .card {
			-webkit-box-flex: 1;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-bottom: 0;
		}
		.card-group > .card + .card {
			margin-left: 0;
			border-left: 0;
		}
		.card-group > .card:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.card-group > .card:not(:last-child) .card-img-top,
		.card-group > .card:not(:last-child) .card-header {
			border-top-right-radius: 0;
		}
		.card-group > .card:not(:last-child) .card-img-bottom,
		.card-group > .card:not(:last-child) .card-footer {
			border-bottom-right-radius: 0;
		}
		.card-group > .card:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.card-group > .card:not(:first-child) .card-img-top,
		.card-group > .card:not(:first-child) .card-header {
			border-top-left-radius: 0;
		}
		.card-group > .card:not(:first-child) .card-img-bottom,
		.card-group > .card:not(:first-child) .card-footer {
			border-bottom-left-radius: 0;
		}
	}

	.card,
	.card-header,
	input {
		border-radius: 15px !important;
	}

	.card {
		box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.1);
	}

	.container-content {
		padding: 0 3rem;
	}
</style>
