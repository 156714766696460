<template>
	<nav class="side-navbar">
        <div class="scroll-container">
            <div
                class="sidebar-header d-flex align-items-center justify-content-center p-3 mb-3">
                <div class="sidenav-header-inner text-center">
                <img class="img-fluid rounded-circle avatar mb-3" :src="getImageUrl(unidade.logo)" alt="Logo da unidade" />
                        <h2 class="h5 text-white text-uppercase mb-0">{{ unidade.nome }}</h2>
                        <p class="text-sm mb-0 text-muted text-version">v{{ version }}</p>
                </div>
                <a class="brand-small text-center" href="#">
                    <p class="h1 m-0">WC</p>
                </a>
            </div>
            <span
                class="text-uppercase text-gray-500 text-sm fw-bold letter-spacing-0 mx-lg-2 heading"
                >MENU</span
            >
            <ul class="list-unstyled">
                <li class="sidebar-item">
                    <router-link
                        class="sidebar-link"
                        :to="'/v1/unidades/' + unidade.id">
                        <img :src="require('@/assets/images/sidebar/home.png')" alt="home">
                        <span
                            style="display: block; text-align: center"
                            >&nbsp; {{ unidade.nome }}
                        </span>
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <a
                        class="sidebar-link"
                        href="#silosDropdown"
                        data-bs-toggle="collapse"
                        aria-expanded="true">
                        <img :src="require('@/assets/images/sidebar/silos.png')" alt="silos">&nbsp; Silos

                    </a>
                    <ul class="collapse list-unstyled show" id="silosDropdown">
                        <li v-for="silo in silos" :key="silo.id">
                            <router-link :to="'/v1/unidades/'+ silo.unidade_id +'/silos/' + silo.id" 
                            class="sidebar-link">{{ silo.nome }}</router-link>
                        </li>
                    </ul>
                </li>
                <li class="sidebar-item">
                <a
                        class="sidebar-link"
                        href="#relatoriosDropdown"
                        data-bs-toggle="collapse"
                        :aria-expanded="isActiveRoute('RelatorioEstacaoPage')">
                        <img :src="require('@/assets/images/sidebar/relatorio.png')" alt="relatorio">&nbsp; Relatórios

                    </a>
                <ul :class="['collapse list-unstyled', {'show': isActiveRoute('RelatorioEstacaoPage')}]" id="relatoriosDropdown">
                    <!-- <li>
                        <a class="sidebar-link" href="#relatoriosSecDropdown" data-bs-toggle="collapse">
                            &nbsp; Secadores
                        </a>
                        <ul class="collapse list-unstyled" id="relatoriosSecDropdown">
                            <li>
                                <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/secadores/temperatura'" 
                                    class="sidebar-link">
                                    Temperaturas
                                </router-link>
                            </li>
                            <li>
                                <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/secadores/parametro'" 
                                    class="sidebar-link">
                                    Parâmetros
                                </router-link>
                            </li>
                        </ul>
                    </li> -->
                    <li>
                        <a class="sidebar-link" href="#relatoriosSilosDropdown" data-bs-toggle="collapse">
                            &nbsp; Silos/Armazéns
                        </a>
                        <ul class="collapse list-unstyled" id="relatoriosSilosDropdown">
                            <!-- <li>
                                <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/silos/termometria'" 
                                    class="sidebar-link">
                                    Termometria
                                </router-link>
                            </li> -->
                            <li>
                                <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/silos/aeracao'" 
                                    class="sidebar-link">
                                    Aeração
                                </router-link>
                            </li>
                            <!-- <li>
                                <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/silos/gastos-aeracao'" 
                                    class="sidebar-link">
                                    Gastos Aeração
                                </router-link>
                            </li> -->
                        </ul>
                    </li>
                    <li>
                        <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/estacao'" 
                                    class="sidebar-link">
                                    Histórico Meteorológico
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/usuarios'" 
                                    class="sidebar-link">
                                    Usuários
                        </router-link>
                    </li> -->
                    <!-- <li>
                        <router-link 
                                    :to="'/v1/unidades/' + unidade.id + '/relatorios/logs'" 
                                    class="sidebar-link">
                                    Logs
                        </router-link>
                    </li> -->
                </ul>
            </li>
            <li class="sidebar-item">
                <router-link 
                    class="sidebar-link"
                    :to="'/v1/unidades/' + unidade.id + '/configuracoes/aeracao'"
                    aria-expanded="false">
                    <img :src="require('@/assets/images/sidebar/settings.png')" alt="simulador">&nbsp; Aeração
                </router-link >
            </li>
            </ul>
        </div>
	</nav>
</template>
<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
	methods: {
            getImageUrl(imagePath) {
                return `${process.env.VUE_APP_SERVER}/storage/${imagePath}`;
            },
            isActiveRoute(routeName) {
                return this.$route.name === routeName;
            }
        },
	setup() {
		const version = process.env.VUE_APP_VERSION;
		const store = useStore();
		const silos = ref([]);
		const unidade = ref({});

		watch(() => store.state.silos.silos,(newSilos) => {
				silos.value = newSilos;
			},{ immediate: true } 
		);

		watch(() => store.state.unidade.unidade, (newUnidade) => {
				unidade.value = newUnidade;
			}, { immediate: true }
		);

		return {
			unidade,
			silos,
			version,
		};
	},
}
</script>
<style scoped>
	.avatar {
		width: 10vh;
		height: 10vh;
		border-radius: 50%;
		object-fit: cover;
	}

	.text-version {
		color: #fff !important;
	}

    .scroll-container {
        max-height: 100%;
        overflow-y: auto;
    }

    .scroll-container {
        scrollbar-width: thin !important;
    }
</style>
