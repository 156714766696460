<template>
    <footer class="main-footer">
        <div class="container-fluid">
            <div class="row text-center gy-3">
                <div class="col-sm-6 text-sm-start">
                    <p class="mb-0 text-sm text-gray-600">{{ vue_app_name }} &copy; 2024</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    setup () {
        const vue_app_name = process.env.VUE_APP_NAME;
        return {
            vue_app_name
        }
    }
}
</script>

<style scoped>
.main-footer {
    background: #222;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    width: 100%;
    bottom: 0;
    height: 2.5em;
}
</style>
