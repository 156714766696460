<template>
    <UnidadeLayout>
        <div v-if="successAlert" class="sucessAlert alert alert-success">
            <p>{{ successAlert }}</p>
        </div>
        <div class="container">
          <!-- Card Externo -->
          <div class="account-card">
            <h1 class="card-title"><strong>Minha Conta</strong></h1>

            <!-- Seção do perfil -->
            <div class="settings-section">
                <h2 class="settings-title">Dados Pessoais</h2>
                <form class="settings-form">
                    <div class="form-group">
                        <label for="name">Nome</label>
                        <input type="text" class="form-control" id="name" v-model="name">
                    </div>
                    <div class="form-group">
                        <label for="lastname">Sobrenome</label>
                        <input type="text" class="form-control" id="lastname" v-model="lastname">
                    </div>
                    <div class="form-group">
                        <label for="login">Login</label>
                        <input type="text" class="form-control" id="login" v-model="login">
                    </div>
                    <div class="form-group">
                        <label for="email">E-mail</label>
                        <input type="email" class="form-control" id="email" v-model="email">
                    </div>
                    <p v-if="errors.dadosPessoais" class="error-text">{{ errors.dadosPessoais }}</p>
                    <div class="button-group">
                        <button type="button" class="btn btn-primary" @click="salvar(1)">Salvar</button>
                        <button type="button" class="btn btn-secondary" @click="cancelar(1)">Cancelar</button>
                    </div>
                </form>
            </div>


            <!-- Seção de senha e segurança -->
            <div class="settings-section">
                <h2 class="settings-title">Privacidade e Segurança</h2>
                <form class="settings-form">
                    <h3 class="settings-subtitle">Atualizar senha</h3>
                    <div class="form-group">
                        <label for="old-pass">Senha atual</label>
                        <input type="password" class="form-control" id="old-pass" v-model="atualSenha">
                    </div>
                    <div class="form-group">
                        <label for="new-pass">Nova senha</label>
                        <input type="password" class="form-control" id="new-pass" v-model="novaSenha">
                    </div>
                    <div class="form-group">
                        <label for="confirm-pass">Confirmar nova senha</label>
                        <input type="password" class="form-control" id="confirm-pass" v-model="confirmNovaSenha">
                    </div>
                    <p v-if="errors.senha" class="error-text">{{ errors.senha }}</p>
                    <div class="button-group">
                        <button type="button" class="btn btn-primary" @click="salvar(3)">Atualizar Senha</button>
                        <button type="button" class="btn btn-secondary" @click="cancelar(3)">Cancelar</button>
                    </div>
                </form>
            </div>
          </div>

            <!-- Seção de Unidades Vinculadas -->
            <div class="units-section">
                <h2 class="units-title">Unidades Vinculadas</h2>
                <div class="units-list">
                    <CardRowUnidade v-for="unidade in unidades" :key="unidade.id" :unidade="unidade"/>
                </div>
            </div>
        </div>
    </UnidadeLayout>
</template>

<script>
import UnidadeLayout from "@/components/layouts/UnidadeLayout.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import CardRowUnidade from "@/components/ui/App/CardRowUnidade.vue";
import axios from 'axios';

export default {
    name: "UserSettings",
    components: { UnidadeLayout, CardRowUnidade },
    setup() {
        const store = useStore();
        const user = store.getters['auth/getUser'];
        const unidades = ref([]);

        const name = ref(user?.nome || '');
        const lastname = ref(user?.sobrenome || '');
        const login = ref(user?.usuario || '');
        const email = ref(user?.email || '');

        const atualSenha = ref('');
        const novaSenha = ref('');
        const confirmNovaSenha = ref('');

        const errors = ref({
            dadosPessoais: '',
            senha: '',
        });

        const successAlert = ref('');

        function cancelar(secao) {
            if (secao === 1) {
                name.value = user?.nome || '';
                lastname.value = user?.sobrenome || '';
                login.value = user?.usuario || '';
                email.value = user?.email || '';
            } else {
                atualSenha.value = '';
                novaSenha.value = '';
                confirmNovaSenha.value = '';
            }
            errors.value = {
                dadosPessoais: '',
                senha: '',
            };
            successAlert.value = '';
        }

        async function salvar(secao) {
            errors.value = {
                dadosPessoais: '',
                senha: ''
            };
            successAlert.value = '';

            let novosDados = {};

            if (secao === 1) {
                if (!name.value || !lastname.value || !login.value || !email.value) {
                    errors.value.dadosPessoais = "Todos os campos são obrigatórios.";
                } else {
                    novosDados = {
                        "nome": name.value,
                        "sobrenome": lastname.value,
                        "email": email.value,  
                        "usuario": login.value
                    };
                    try {
                        await axios.put(`/usuario/update/${user.id}`, novosDados);
                        successAlert.value = "Dados atualizados!";
                        window.scrollTo(top);
                        user.nome = name.value;
                        user.sobrenome = lastname.value;
                        user.usuario = login.value;
                    } catch (error) {
                        alert("Erro ao salvar dados");
                    }
                }
            } else {
                if (novaSenha.value != confirmNovaSenha.value) {
                    errors.value.senha = "As senhas não coincidem.";                
                } else {
                    novosDados = { 
                        "senhaAtual": atualSenha.value,
                        "novaSenha": novaSenha.value,
                        "novaSenha_confirmation": confirmNovaSenha.value
                    }
                    try{
                        await axios.put(`/usuario/update/${user.id}/password`, novosDados);
                        successAlert.value = "Senha atualizada!";
                        window.scrollTo(top);
                    } catch (error) {                    
                        errors.value.senha = "Senha atual incorreta.";
                    }
                }
            }
        }

        return {
            name,
            lastname,
            login,
            email,
            atualSenha,
            novaSenha,
            confirmNovaSenha,
            unidades,
            cancelar,
            salvar,
            errors,
            successAlert
        };
    }
};
</script>

<style scoped>
.container {
  display: flex;
  margin: 0 auto;
  padding: 16px;
  min-width: 550px;
  flex-direction: column;
  align-items: center;
}

.account-card, .units-section{
    background-color: #ffffff;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    margin-bottom: 16px;
    width: 100%;
    max-width: 500px;
}

.card-title {
    font-size: 20px;
    font-weight: 400;
    color: #202124;
    margin-bottom: 16px;
    
}

.settings-section, .units-section {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    margin-bottom: 16px;
}

.settings-title, .units-title {
    font-size: 18px;
    font-weight: 400;
    color: #202124;
    margin-bottom: 16px;
}

.settings-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #202124;
    margin-bottom: 16px;
}

.settings-form {
    max-width: 400px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 12px;
}

.form-group label {
    display: block;
    font-size: 14px;
    color: #5f6368;
    margin-bottom: 4px;
}

.form-control {
    width: calc(100% - 16px);
    padding: 6px 8px;
    font-size: 14px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background-color: #ffffff;
     margin: 0 auto;
}

.form-control:focus {
    border-color: #33b35a;
    box-shadow: 0 0 0 0.2rem rgba(26, 115, 232, 0.2);
}

.button-group {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.btn-primary {
    background-color: #33b35a;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #2b8a3e;
}

.btn-secondary {
    background-color: #f1f3f4;
    color: #202124;
    border: 1px solid #dadce0;
}

.btn-secondary:hover {
    background-color: #e8eaf0;
    color: red;
}

.units-section {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
}

.units-title {
    font-size: 18px;
    font-weight: 400;
    color: #202124;
    margin-bottom: 14px;
}

.units-list {
    display: flex;
    flex-direction: column;
}

.card-row {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-decoration: none;
    color: inherit;
    background-color: #fff;
    transition: transform 0.2s, box-shadow 0.2s;
}

.card-row:hover {
    background-color: #f4f4f4;
    transform: translateY(-4px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.error-text{
    color: red;
}
.sucessAlert{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    height: 50px;
    max-width: 500px; 
    margin-top: 20px;
    justify-content: center;
}
.sucessAlert * {
    margin: 0 auto;
}
</style>
