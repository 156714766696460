<template>
    <div class="layout">
        <NavBar />
        <main class="main-content">
            <slot></slot>
        </main>
        <FooTer />
    </div>
</template>

<script>
import FooTer from '../ui/FooTer.vue';
import NavBar from "../ui/NavBar.vue";

export default {
    components: {
        NavBar,
        FooTer
    }
}
</script>

<style scoped>
    .layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .main-content {
        flex: 1;
    }
</style>
