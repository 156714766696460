<template>
    <div class="card h-100">
        <div
            class="card-header card-header-v2 display-space-3"
            style="display: flex; justify-content: space-between; padding: 14pt"
        >
            Informações gerais
            <img
                src="/images/icons/home.png"
                alt="home"
                style="background-color: black; border-radius: 15px"
            />
        </div>
        <div class="card-body card-body-v2">
            <ul v-if="silo.programa">
                <InformacaoLi descricao="Produto" :valor="silo.produto.descricao" />
                <InformacaoLi descricao="Programa" :valor="silo.programa.descricao" />
                <li>Média Atual: {{ `${silo.media_atual} °C` }}</li>
                <li>
                    Aeração:
                    {{ silo.bloqueio_aeracao == 1 ? "Bloqueada" : "Liberada" }}
                </li>
                <li>Carga atual: {{ carga_atual }} sacas</li>
                <li>
                    Temperatura de Alerta:
                    {{ `${silo.temperatura_alerta} °C ` }}
                </li>
                <li>
                    Leituras Automaticas:
                    <span
                        class="badge rounded-pill bg-success"
                        v-if="silo.bloqueio_aeracao == 0"
                        >Habilitado</span
                    >
                    <span class="badge rounded-pill bg-danger" v-else
                        >Desabilitado</span
                    >
                </li>
            </ul>
            <div
                class="container-nivel"
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 5pt;
                    padding-bottom: 5pt;
                "
            >
                <span>
                    Nível:
                    <span id="nivel_text">{{
                        silo.tipo_nivel == 1
                            ? silo.nivel_automatico
                            : silo.nivel_manual
                    }}</span>
                    %
                </span>
                <span
                    class="badge rounded-pill bg-info"
                    v-if="silo.tipo_nivel == 1"
                    >Nível Automatico</span
                >
                <span class="badge rounded-pill bg-warning" v-else
                    >Nível Manual</span
                >
            </div>
            <div class="progress">
                <div
                    class="progress-bar green"
                    :style="
                        'width: ' +
                        (silo.tipo_nivel == 1
                            ? silo.nivel_automatico
                            : silo.nivel_manual) +
                        '%'
                    "
                ></div>
            </div>
            <div
                class="line-row"
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                "
            >
                <span>Carga atual: {{ carga_atual }} sacas</span>
                <span>Capacidade: {{ silo.capacidade }}</span>
            </div>
            <CanvasVista :cabos="silo.cabos" :silotipo="silo.tipo" />
        </div>
    </div>
</template>

<script>
import CanvasVista from "./CanvasVista.vue";
import InformacaoLi from "./InformacaoLi.vue";

export default {
    components: {
        CanvasVista,
        InformacaoLi
    },
    props: {
        silo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            CanvasVista,
        };
    },
    computed: {
        carga_atual() {
            if (this.silo && this.silo.tipo_nivel && (this.silo.nivel_automatico || this.silo.nivel_manual)) {
                const nivel =  this.silo.tipo_nivel == 1 ? this.silo.nivel_automatico : this.silo.nivel_manual;
                const carga_atual = Math.round(this.silo.capacidade * nivel / 100);
                return carga_atual
            }
            return 0
        }
    }
};
</script>

<style scoped>
.green {
    background-color: #33b35a !important;
}
</style>
