import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';
import auth from "./modules/auth";
import unidades from "./modules/unidades";
import unidade from './modules/unidade';
import silos from "./modules/silos";
import silo from "./modules/silo";
import leituras from "./modules/leituras";
import estacao from './modules/estacao';
const store = createStore({
	modules: {
		auth,
		unidades,
		unidade,
		silos,
        silo,
        leituras,
		estacao,
	},
    mutations: {
        resetState(state) {
            Object.keys(state).forEach(key => {
                if (store._modulesNamespaceMap[`${key}/`]) {
                    store.commit(`${key}/reset`);
                }
            });
        }
    },
    plugins: [
        createPersistedState({
            key: 'vuex-winckieel-cloud',
            storage: window.localStorage,
            paths: ['auth', 'unidades', 'unidade', 'silos'] 
        })
    ]
});

export default store;
