<template>
    <div class="col-sm-5">
        <div class="card">
            <div class="card-header card-header-v2">
                Programa de curvas
            </div>
            <div class="card-body card-body-v2">
                <div class="margin-b-1">
                    <p style="font-size: 8pt">Programa de curvas utiliza como parâmetro a médial geral, atualizada sempre após uma leitura concluída. Média atual:  {{ programaCurva['media'] }} °C.</p>
                </div>
                <div class="row" style="display: flex;justify-content: center;">
                    <img src="/images/silos/programa-curvas.jpg" alt="curvas" style="width: 65%;">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        parametros: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        programaCurva() {
            if (this.parametros && this.parametros['4']) {
                return this.parametros['4']
            }
            return {
                "media": ""
            }
        },
    }
}
</script>
<style scoped>
    .card-header {
    display: flex;
    justify-content: space-between;
    padding: 14pt;
    }

    .card-body {
    padding: 16px;
    }

    .form-group {
    margin-bottom: 1rem;
    }

    .h-100 {
    height: 100%;
    }

    .mb-4 {
    margin-bottom: 1.5rem;
    }
</style>