<template>
    <section class="col-sm-3">
        <router-link :to="'/v1/unidades/'+ silo.unidade_id +'/silos/' + silo.id" class="a-silo">
            <div class="card card-silo">
                <div class="card-header">
                    <h5>{{ silo.nome }}</h5>
                </div>                           
                <div class="card-footer">
                    <div class="div-content-nivel">
                        <div>
                            <p>Nível: {{ (silo.tipo_nivel == 1 ? silo.nivel_automatico : silo.nivel_manual) }} %</p>
                            <span
                                class="badge rounded-pill bg-info"
                                v-if="silo.tipo_nivel == 1"
                                >Nível Automatico</span
                            >
                            <span class="badge rounded-pill bg-warning" v-else
                                >Nível Manual</span
                            >
                        </div>
                        <img :src="nivelImg" alt="" style="width: 12vh;">
                    </div>
                    <div class="progress">
                        <div class="progress-bar green" :style="'width: '+ (silo.tipo_nivel == 1 ? silo.nivel_automatico : silo.nivel_manual) +'%'"></div>
                    </div>
                    <div class="line-row">
                        <p>Carga atual: {{ carga_atual }}| Capacidade: {{ silo.capacidade }}</p>
                    </div>
                </div>
            </div>
        </router-link>
    </section>
</template>
<script>
export default {
    props: {
        silo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            imgNivel: 0
        };
    },
    computed: {
        nivelImg() {
            return `/images/silos/niveis/SILO${this.imgNivel}.png`;
        },
        carga_atual() {
            if (this.silo && this.silo.tipo_nivel && (this.silo.nivel_automatico || this.silo.nivel_manual)) {
                const nivel =  this.silo.tipo_nivel == 1 ? this.silo.nivel_automatico : this.silo.nivel_manual;
                const carga_atual = Math.round(this.silo.capacidade * nivel / 100);
                return carga_atual
            }
            return 0
        }
    },
    watch: {
        silo(silo) {
            this.setImgNivel(silo);
        }
    },
    mounted() {
        this.setImgNivel(this.silo);
    },
    methods: {
        setImgNivel(silo) {
            const nivel = silo.tipo_nivel == 1 ? silo.nivel_automatico : silo.nivel_manual;
            this.imgNivel = this.arredondarNivel(nivel);
        },
        arredondarNivel(nivel) {
            if (nivel <= 0) return 0;
            if (nivel > 100) return 100;
            return Math.ceil(nivel / 10) * 10;
        }
    }
}
</script>
<style scoped>
.div-content-nivel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.green {
    background-color: #33b35a !important;
}
</style>