<template>
    <div class="row content-programas">
        <div class="col-sm-3 col-w card-programa">
            <div id="programaLivre" class="card h-100">
                <div class="card-header card-header-v2 display-space-3">
                    Programa Livre
                </div>
                <div class="card-body card-body-v2">
                    <div class="form-group">
                        <label for="temperatura_maxima_livre">Temperatura máxima:</label>
                        <input type="number" required class="form-control form-control-v2" name="temperatura_maxima_livre" id="temperatura_maxima_livre" v-model="programaLivre['temperatura_maxima']">
                    </div>
                    <div class="form-group">
                        <label for="temperatura_minima_livre">Temperatura mínima:</label>
                        <input type="number" required class="form-control form-control-v2" name="temperatura_minima_livre" id="temperatura_minima_livre" v-model="programaLivre['temperatura_minima']">
                    </div>
                    <div class="form-group">
                        <label for="umidade_maxima_livre">Umidade máxima:</label>
                        <input type="number" required class="form-control form-control-v2" name="umidade_maxima_livre" id="umidade_maxima_livre" v-model="programaLivre['umidade_maxima']">
                    </div>
                    <div class="form-group">
                        <label for="umidade_minima_livre">Umidade mínima:</label>
                        <input type="number" required class="form-control form-control-v2" name="umidade_minima_livre" id="umidade_minima_livre" v-model="programaLivre['umidade_minima']">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-w card-programa">
            <div id="programaLivreTemporizado" class="container-programa card h-100">
                <div class="card-header card-header-v2 display-space-3">
                    Programa Livre Temporizado
                </div>
                <div class="card-body card-body-v2 cardProgramaLivreTemporizado">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="temperatura_maxima_livre_temporizado">Temperatura máxima:</label>
                                <input type="number" required class="form-control form-control-v2" name="temperatura_maxima_livre_temporizado" id="temperatura_maxima_livre_temporizado" v-model="programaLivreTemporizado['temperatura_maxima']">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="temperatura_minima_livre_temporizado">Temperatura mínima:</label>
                                <input type="number" required class="form-control form-control-v2" name="temperatura_minima_livre_temporizado" id="temperatura_minima_livre_temporizado" v-model="programaLivreTemporizado['temperatura_minima']">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="umidade_maxima_livre_temporizado">Umidade máxima:</label>
                                <input type="number" required class="form-control form-control-v2" name="umidade_maxima_livre_temporizado" id="umidade_maxima_livre_temporizado" v-model="programaLivreTemporizado['umidade_maxima']">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="umidade_minima_livre_temporizado">Umidade mínima:</label>
                                <input type="number" required class="form-control form-control-v2" name="umidade_minima_livre_temporizado" id="umidade_minima_livre_temporizado" v-model="programaLivreTemporizado['umidade_minima']">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="horario_inicio">Horário de Início:</label>
                                <input type="time" class="form-control form-control-v2" name="horario_inicio" id="horario_inicio" v-model="programaLivreTemporizado['horario_inicio']">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="horario_final">Horário de término:</label>
                                <input type="time" class="form-control form-control-v2" name="horario_final" id="horario_final" v-model="programaLivreTemporizado['horario_final']">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3 col-w card-programa">
            <div id="programaEquilibrioHigroscopico" class="card h-100">
                <div class="card-header card-header-v2 display-space-3">
                    Programa Equilíbrio Higroscópico
                </div>
                <div class="card-body card-body-v2">
                    <div class="form-group">
                        <label for="umidade_maxima_equilibrio">Umidade máxima:</label>
                        <input type="number" required class="form-control form-control-v2" name="umidade_maxima_equilibrio" id="umidade_maxima_equilibrio" v-model="programaEquilibrioHigroscopico['umidade_maxima']">
                    </div>
                    <div class="form-group">
                        <label for="umidade_minima_equilibrio">Umidade mínima:</label>
                        <input type="number" required class="form-control form-control-v2" name="umidade_minima_equilibrio" id="umidade_minima_equilibrio" v-model="programaEquilibrioHigroscopico['umidade_minima']">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        parametros: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        programaLivre() {
            if (this.parametros && this.parametros['1']) {
                return this.parametros['1']
            }
            return {
                "temperatura_maxima": "",
                "temperatura_minima": "",
                "umidade_maxima": "",
                "umidade_minima": ""
            }
        },
        programaLivreTemporizado() {
            if (this.parametros && this.parametros['2']) {
                return this.parametros['2']
            }
            return {
                "temperatura_maxima": "",
                "temperatura_minima": "",
                "umidade_maxima": "",
                "umidade_minima": "",
                "horario_inicio": "",
                "horario_final": ""
            }        
        },
        programaEquilibrioHigroscopico() {
            if (this.parametros && this.parametros['3']) {
                return this.parametros['3']
            }
            return {
                "umidade_maxima": "",
                "umidade_minima": ""
            }        
        },
    },
    methods: {
        getParametrosProgramas() {
            return [
                {
                    "programa_id": 1,
                    "temperatura_maxima": this.programaLivre['temperatura_maxima'],
                    "temperatura_minima": this.programaLivre['temperatura_minima'],
                    "umidade_maxima": this.programaLivre['umidade_maxima'],
                    "umidade_minima": this.programaLivre['umidade_minima']
                },
                {
                    "programa_id": 2,
                    "temperatura_maxima": this.programaLivreTemporizado['temperatura_maxima'],
                    "temperatura_minima": this.programaLivreTemporizado['temperatura_minima'],
                    "umidade_maxima": this.programaLivreTemporizado['umidade_maxima'],
                    "umidade_minima": this.programaLivreTemporizado['umidade_minima'],
                    "horario_inicio": this.programaLivreTemporizado['horario_inicio'].substring(0, 5),
                    "horario_final": this.programaLivreTemporizado['horario_final'].substring(0, 5)
                },
                {
                    "programa_id": 3,
                    "umidade_maxima": this.programaEquilibrioHigroscopico['umidade_maxima'],
                    "umidade_minima": this.programaEquilibrioHigroscopico['umidade_minima']
                }
            ]
        }
    }
}
</script>