import axios from "axios";
import store from "@/store";
import router from "@/router"; 

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        const token = store.state.auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

let isAlreadyFetchingAccessToken = false;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        if (response && response.status === 401) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                store.dispatch("auth/logout").then(() => {
                    isAlreadyFetchingAccessToken = false;
                    router.push("/login");
                });
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
