import axios from "@/axios";

const getDefaultState = () => ({
    unidades: []
});
  
const state = getDefaultState();

const mutations = {
	SET_UNIDADES(state, unidades) {
		state.unidades = unidades;
	},
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
	async fetchUnidades({ commit }) {
		try {
			const response = await axios.get("/unidades");
			commit("SET_UNIDADES", response.data.unidades);
		} catch (error) {
			console.error("Erro ao buscar unidades:", error);
			throw error;
		}
	}
};

const getters = {
	getUnidades: (state) =>  state.unidades
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
