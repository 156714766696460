import axios from '../../axios';

const getDefaultState = () => ({
    estacao: null
});

const state = getDefaultState();

const getters = {
    estacao: state => state.estacao,
};

const actions = {
    async fetchEstacaoData({ commit }, unidadeId) {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        try {
            const response = await axios.get(`/estacao/unidade/${unidadeId}/${formattedDate}/${formattedDate}`);
            const data = response.data;
            if (data.length > 0) {
                commit('SET_ESTACAO', data[data.length -1]);
            }
            else {
                commit('CLEAR_ESTACAO');
            }
        }
        catch (error) {
            commit('CLEAR_ESTACAO');
        }
    },
    async fetchEstacaoDataRelatorio(_, {unidadeId, dataInicio, dataFim}) {
        try {
            const response = await axios.get(`/estacao/unidade/${unidadeId}/${dataInicio}/${dataFim}`);
            return response.data;
        } catch (error) {
            console.error("Failed to fetch data:", error);
            throw error;
        }
    },
};

const mutations = {
    SET_ESTACAO(state, data) {
        state.estacao = data;
    },
    CLEAR_ESTACAO(state) {
        state.estacao = null;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
