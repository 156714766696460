<template>
    <MainLayout>
        <div class="bg-gray-200 text-sm">
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 py-3">
                        &nbsp; &nbsp; &nbsp;
                        <li class="breadcrumb-item">
                            <a class="fw-light" href="{{ route('home') }}">Winckieel</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="fw-light" href="{{ route('home') }}">Silos/armazéns</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="fw-light" href="{{ route('home') }}">Relatórios</a>
                        </li>
                        <li class="breadcrumb-item active fw-light" aria-current="page">
                            Aeração
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <header class="py-1">
            <div class="container-fluid py-2"></div>
        </header>
        <section class="pb-5 min-h-85">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <div class="card">
                <div
                    class="card-header card-header-v2 display-space-3"
                    style="
                    display: flex;
                    justify-content: space-between;
                    padding: 14pt;
                    "
                >
                    <h3 class="h4 mb-0">
                    Relatório da Aeração dos silos/armazéns:
                    </h3>
                </div>
                <div class="card-body">
                    <p class="text-sm">Selecione as informações abaixo:</p>
                    <form id="relatorio" method="post">
                    <div class="row">
                        <div class="col-sm-3">
                        <label class="col-sm-12 form-label"
                            >Selecione os silos/armazéns:
                        </label>
                        <div class="row">
                            <div>
                            <div
                                v-for="silo in silos"
                                :key="silo.id"
                                class="form-check"
                            >
                                <input
                                class="form-check-input input-locais-checkbox"
                                :id="silo.id"
                                :value="silo.id"
                                type="radio"
                                name="local"
                                v-model="selectedSilo"
                                />
                                <label class="form-check-label" :for="silo.id">{{
                                silo.nome
                                }}</label>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-sm-3">
                        <label class="col-sm-12 form-label"
                            >Datas pré-definidas:</label
                        >
                        <div class="col-sm-12">
                            <select
                            class="form-select mb-3"
                            name="data_pre_definida"
                            v-model="data_pre_definida"
                            >
                            <option value="null" selected>
                                Selecione um período
                            </option>
                            <option value="0">Dia atual</option>
                            <option value="1">Dia anterior</option>
                            <option value="2">Últimos 7 dias</option>
                            <option value="3">Últimos 15 dias</option>
                            <option value="4">Últimos 30 dias</option>
                            <option value="5">Mes atual</option>
                            </select>
                            <small style="font-size: 8pt"
                            >* Quando selecionado alguma opção acima, as datas de
                            início e término serão ignoradas.</small
                            >
                        </div>
                        </div>
                        <div class="col-sm-3">
                        <label class="form-label" for="data_inicio"
                            >Data de início:
                        </label>
                        <input
                            class="form-control"
                            id="data_inicio"
                            name="data_inicio"
                            type="date"
                            v-model="dataInicio"
                        />
                        </div>
                        <div class="col-sm-3">
                        <label class="form-label" for="data_final"
                            >Data final:
                        </label>
                        <input
                            class="form-control"
                            id="data_final"
                            name="data_final"
                            type="date"
                            v-model="dataFinal"
                        />
                        </div>
                    </div>
                    <hr />
                    <button
                        class="btn btn-primary btn-view-relatorio"
                        id="viewRelatorio"
                        type="button"
                        @click="gerarRelatorio"
                    >
                        Visualizar relatório
                    </button>
                    &nbsp; &nbsp;
                    </form>
                </div>
                </div>
            </div>
            </div>
        <div class="row">
          <LoadingRelatorio v-if="loadingDados" />
          <div v-if="aeracaoData.length > 0" class="col-lg-12">
            <div class="card">
              <div
                class="card-body container-relatorio table-responsive"
                id="tableReport"
                style="margin: 10px"
              >
                <div>
                  <div class="header-table">
                    <h2 class="title-pdf">Relatório da Aeração</h2>
                    <span>Sistema de gerenciamento local</span>

                    <div style="margin: 25px; margin-bottom: 40px">
                      <h3 class="header-unidade">
                        Unidade: {{ unidade.nome }}
                      </h3>
                      <span
                        >Intervalo de datas selecionadas: {{ dataInicio }} até
                        {{ dataFinal }}</span
                      >
                    </div>
                  </div>

                  <table class="table table-bordered table-striped">
                    <thead>
                      <th
                        colspan="8"
                        style="padding: 12px; text-transform: uppercase"
                      >
                        {{ selectedSiloNome }}
                      </th>
                    </thead>
                    <tbody>
                      <th colspan="8" style="padding: 12px">
                        PARÂMETROS DA AERAÇÃO
                      </th>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Status dos motores</th>
                        <th>Modo de operação</th>
                        <th>Produto</th>
                        <th>Dados Meteorológicos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in aeracaoData" :key="item.data">
                        <td>{{ item.aeracao.data }}</td>
                        <td>
                          <ul v-for="motor in item.motores" :key="motor">
                            <li>
                              <p style="margin: 3px">{{ motor.descricao }}:</p>
                              <p
                                style="margin: 3px"
                                :class="{
                                  'badge rounded-pill bg-success':
                                    motor.status == 1,
                                  'badge rounded-pill bg-danger':
                                    motor.status != 1,
                                }"
                              >
                                {{ motor.status == 1 ? "Ligado" : "Desligado" }}
                              </p>
                            </li>
                          </ul>
                        </td>
                        <td>
                          {{
                            item.aeracao.modo_operacao == 1
                              ? "Automático"
                              : "Manual"
                          }}
                        </td>
                        <td>{{ getProdutoNome(item.aeracao.produto) }}</td>
                        <td>
                          <ul>
                            <li>
                              Temperatura: {{ item.estacao.temperatura }} ºC
                            </li>
                            <li>Umidade: {{ item.estacao.umidade }} %</li>
                            <li>
                              <p
                                :class="{
                                  'badge rounded-pill bg-info':
                                    item.estacao.chuva == 1,
                                }"
                              >
                                {{
                                  item.estacao.chuva == 1
                                    ? "Chovendo"
                                    : "Sem chuva"
                                }}
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import MainLayout from "@/components/layouts/MainLayout.vue";
import LoadingRelatorio from "@/components/ui/App/LoadingRelatorio.vue";

export default {
  components: {
    MainLayout,
    LoadingRelatorio,
  },

  setup() {
    const unidade = ref({});
    const store = useStore();
    const silos = ref([]);
    const aeracaoData = ref([]);
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    const dataInicio = ref(formattedDate);
    const dataFinal = ref(formattedDate);
    const data_pre_definida = ref("null");
    const loadingDados = ref(false);
    const selectedSilo = ref(null);
    const selectedSiloNome = ref(null);
    const toast = useToast();

    const produtos = {
      1: "Vazio",
      2: "Cevada",
      3: "Feijão",
      4: "Milho",
      5: "Arroz",
      6: "Sorgo",
      7: "Soja",
      8: "Trigo",
      9: "Canola",
      10: "Azevém",
      11: "Linhaça",
      12: "Painço",
      13: "Alpiste",
      14: "Milheto",
      15: "Aveia",
      16: "Girassol",
      17: "Outro",
    };

    const getProdutoNome = (codigo) => {
      return produtos[codigo];
    };

    watch(
      () => store.state.silos.silos,
      (newSilos) => {
        silos.value = newSilos;
      },
      { immediate: true }
    );

    watch(
      () => store.state.unidade.unidade,
      (newUnidade) => {
        unidade.value = newUnidade;
      },
      { immediate: true }
    );

    const alterarNomeSilo = () => {
      selectedSiloNome.value = silos.value.find(
        (s) => s.id === selectedSilo.value
      )?.nome;
    };

    const datasPredefinidas = (periodo) => {
      const today = new Date();
      let dataInicio;
      let dataFinal = today.toISOString().split("T")[0];
      alterarNomeSilo();

      switch (periodo) {
        case "0":
          dataInicio = dataFinal;
          break;
        case "1":
          dataInicio = new Date();
          dataInicio.setDate(dataInicio.getDate() - 1);
          dataInicio = dataInicio.toISOString().split("T")[0];
          dataFinal = dataInicio;
          break;
        case "2":
          dataInicio = new Date();
          dataInicio.setDate(dataInicio.getDate() - 7);
          dataInicio = dataInicio.toISOString().split("T")[0];
          break;
        case "3":
          dataInicio = new Date();
          dataInicio.setDate(dataInicio.getDate() - 15);
          dataInicio = dataInicio.toISOString().split("T")[0];
          break;
        case "4":
          dataInicio = new Date();
          dataInicio.setDate(dataInicio.getDate() - 30);
          dataInicio = dataInicio.toISOString().split("T")[0];
          break;
        case "5":
          dataInicio = new Date(today.getFullYear(), today.getMonth(), 1)
            .toISOString()
            .split("T")[0];
          break;
      }
      return { dataInicio, dataFinal };
    };

    const gerarRelatorio = async () => {
      aeracaoData.value = 0;
      loadingDados.value = true;
      let inicio;
      let fim;
      const siloId = selectedSilo.value;

      if (data_pre_definida.value !== "null") {
        const periodo = datasPredefinidas(data_pre_definida.value);
        inicio = periodo.dataInicio;
        fim = periodo.dataFinal;
      } else {
        inicio = dataInicio.value;
        fim = dataFinal.value;
      }

      if (!inicio || !fim || !siloId) {
        toast.warning("Preencha os silos e datas corretamente.");
        loadingDados.value = false;
        return;
      }

      try {
        const data = await store.dispatch("silo/fetchAeracaoDataRelatorio", {
          siloId,
          dataInicio: inicio,
          dataFim: fim,
        });

        aeracaoData.value = data.sort(
          (a, b) => new Date(a.data) - new Date(b.data)
        );
        loadingDados.value = false;
      } catch (error) {
        toast.error("Ocorreu um erro ao gerar o relatório");
        aeracaoData.value = [];
        loadingDados.value = false;
      }
    };

    return {
      silos,
      unidade,
      aeracaoData,
      dataInicio,
      dataFinal,
      data_pre_definida,
      selectedSilo,
      gerarRelatorio,
      loadingDados,
      LoadingRelatorio,
      selectedSiloNome,
      getProdutoNome,
    };
  },
};
</script>

<style scoped>
table {
  width: 100%;
  text-align: center;
}

thead th {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border-bottom: 2px solid #dee2e6;
}

tbody th {
  background-color: #e9ecef;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #dee2e6;
}

tbody td {
  text-align: center;
  padding: 10px;
  vertical-align: middle;
}

tbody ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

tbody ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header-table {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.title-pdf,
.header-unidade {
  word-wrap: break-word;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.3rem;
}

.btn-view-relatorio {
  background-color: #1c1c1c;
  color: white;
  border: 1px solid black;
}

.btn-view-relatorio:hover {
  background-color: grey;
  color: #1c1c1c;
  border: 1px solid black;
}
</style>
