import axios from "@/axios";

const getDefaultState = () => ({
    silos: []
});

const state = getDefaultState();

const mutations = {
    SET_SILOS(state, silos) {
        state.silos = silos;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
}

const actions = {
    async fetchSilosByUnidade({ commit }, unidade) {
        try {
            const response = await axios.get(`/unidades/${unidade}/silos`);
            commit('SET_SILOS', response.data.silos);
        } catch (error) {
            console.error("Erro ao buscar silos:", error);
			throw error;
        }
    },
};

export default {
    namespaced: true,
    actions,
    mutations,
    state
}