import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import LoginPage from "@/views/LoginPage.vue";
import UnidadesPage from "@/views/app/UnidadesPage.vue";
import UnidadePage from "@/views/app/Unidades/UnidadePage.vue";
import SiloPage from "@/views/app/Unidades/Unidade/SiloPage.vue";
import UsuarioPage from "@/views/app/UsuarioPage.vue";
import RelatorioEstacao from "@/views/app/Relatorios/RelatorioEstacao.vue";
import AeracaoPage from "@/views/app/Unidades/Unidade/AeracaoPage.vue";
import RelatorioAeracao from "@/views/app/Relatorios/Silos/RelatorioAeracao.vue";

const routes = [
	{
		path: "/login",
		name: "LoginPage",
		component: LoginPage,
        meta: { title: 'Login - Winckieel Cloud' }
	},
	{
		path: "/minha-conta",
		name: "UsuarioPage",
		component: UsuarioPage,
        meta: { requiresAuth: true, title: 'Conta - Winckieel Cloud' }
	},
	{
		path: "/v1/unidades",
		name: "UnidadesPage",
		component: UnidadesPage,
		meta: { requiresAuth: true, title: 'Unidades - Winckieel Cloud'},
	},
	{
		path: "/v1/unidades/:unidade",
		name: "UnidadeDetailsPage",
		component: UnidadePage,
		meta: { requiresAuth: true, title: 'Winckieel Cloud' },
	},
	{
		path: "/v1/unidades/:unidade/silos/:silo",
		name: "SiloDetailsPage",
		component: SiloPage,
		meta: { requiresAuth: true, title: 'Silo - Winckieel Cloud' },
	},
	{
		path: "/v1/unidades/:unidade/relatorios/estacao",
		name: "RelatorioEstacaoPage",
		component: RelatorioEstacao,
		meta: { requiresAuth: true, title: 'Relatório Estação - Winckieel Cloud' },
	},
    {
        path: "/v1/unidades/:unidade/relatorios/silos/aeracao",
		name: "RelatorioAeracaoPage",
		component: RelatorioAeracao,
		meta: { requiresAuth: true, title: 'Relatório Aeração - Winckieel Cloud' },
    },
    {
        path: "/v1/unidades/:unidade/configuracoes/aeracao",
		name: "AeracaoPage",
		component: AeracaoPage,
		meta: { requiresAuth: true, title: 'Aeração - Winckieel Cloud' },
    },
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		redirect: "/v1/unidades"
		
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters["auth/isAuthenticated"]) {
			next({ name: "LoginPage" });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
