<template>
  <div class="row">
    <select
      class="form-control select-leituras btn-termoweb"
      @change="onChange" v-model="selectedLeitura">
      <option disabled value="">Selecione uma leitura</option>
      <option v-for="(leitura, index) in leituras" :key="index" :value="leitura._id">{{ leitura.data }}</option>
    </select>
  </div>
</template>

<script>
export default {
    props: {
        leituras: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedLeitura: "",
        };
    },
    methods: {
        onChange() {
            this.$emit('changeLeitura', this.selectedLeitura);
        },
    },
};
</script>

<style scoped></style>
