import axios from "@/axios";

const getDefaultState = () => ({
    silo: []
});

const state = getDefaultState();

const mutations = {
    SET_SILO(state, silo) {
        state.silo = silo;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
}

const actions = {
    async fetchSiloById({commit}, siloId) {
        try {
            const response = await axios.get(`/silos/${siloId}`)
            commit('SET_SILO', response.data)
            return response.data;
        } catch (error) {
            console.error("Error to request silo, message: ", error)
            throw error;
        }
    },
    async saveParametrosAeracao(_, { data, siloId }) {
        try {
            const response = await axios.post(`/silos/${siloId}/parametros/aeracao/save`, data)
            return response.data;
        } catch (error) {
            console.error("Error to request parametros save, message: ", error)
            throw error;
        }
    },
    async saveAeracao(_, { data, siloId }) {
        try {
            const response = await axios.post(`/silos/${siloId}/aeracao/save`, data)
            return response.data;
        } catch (error) {
            console.error("Error to request parametros save, message: ", error)
            throw error;
        }
    },
    async fetchAeracaoDataRelatorio(_, {siloId, dataInicio, dataFim}) {
        try {
            const response = await axios.get(`/silos/${siloId}/aeracao/${dataInicio}/${dataFim}`);
            return response.data;
        } catch (error) {
            console.error("Failed to fetch data:", error);
            throw error;
        }
    }
};

export default {
    namespaced: true,
    actions,
    mutations,
    state
}