<template>
    <div class="bg-gray-200 text-sm">
        <div class="container-fluid">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 py-3">
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <li class="breadcrumb-item" v-for="item in items" :key="item">
                        <router-link class="fw-light" :to="item.url">{{ item.descricao }}</router-link>
                    </li>
                    <li class="breadcrumb-item active fw-light" aria-current="page">{{ page }}</li>
                </ol>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    props : {
        items: {
            type: Object,
            default: () => {}
        },
        page: {
            type: String,
            default: () => ""
        }
    }
}
</script>