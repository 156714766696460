<template>
    <div class="canvas-container">
        <canvas ref="canvasVistaSuperior" width="350" height="350">
            O seu browser não suporta canvas.
        </canvas>
    </div>
</template>

<script>
export default {
    props: {
        cabos: {
            type: Object,
            required: true,
        },
        silotipo: {
            type: String,
            required: true,
        },
    },
    watch: {
        cabos(newVal) {
            this.renderVistaSuperior(newVal);
        },
    },
    mounted() {
        this.renderVistaSuperior(this.cabos);
    },
    methods: {
        drawText(ctx, text, x, y) {
            ctx.font = "17px Arial";
            ctx.fillStyle = "white";
            ctx.fillText(text, x, y);
        },
        degreeToRadians(angle) {
            return (angle * Math.PI) / 180;
        },
        drawFillCircle(ctx, x, y, size, color) {
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.arc(x, y, size, 0, 2 * Math.PI);
            ctx.fill();
        },
        drawEmptyCircle(ctx, x, y, size, color) {
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.arc(x, y, size, 0, 2 * Math.PI);
            ctx.stroke();
        },
        drawArcCircle(ctx, x, y, radius) {
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI);
            ctx.strokeStyle = "black"; // Cor do contorno preto
            ctx.lineWidth = 1; // Largura da linha fina
            ctx.stroke();
        },
        renderVistaSuperior(cabos) {
            // Verificar se cabos é um array com itens
            if (!Array.isArray(cabos) || cabos.length === 0) {
                return;
            }

            const canvas = this.$refs.canvasVistaSuperior;
            const ctx = canvas.getContext("2d");
            const bluecolor = "#006400";

            // Limpar o canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = 150;

            // Função para desenhar o silo
            const renderSilo = (data) => {
                ctx.fillStyle = "rgb(188,190,188)";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                const uniqueArcos = new Set();
                const cabosPorArco = {};

                // Iterar sobre o array de objetos
                data.forEach((obj) => {
                    const arco = obj.arco;
                    const cabo = obj.cabo;

                    uniqueArcos.add(arco);
                    if (!cabosPorArco[arco]) {
                        cabosPorArco[arco] = [];
                    }

                    cabosPorArco[arco].push(cabo);
                });

                const quantidadeArcosUnicos = uniqueArcos.size;

                this.drawFillCircle(ctx, centerX, centerY, radius, "white");

                ctx.beginPath();
                ctx.rect(centerX - 25, centerY + 125, 60, 30);
                ctx.fill();

                const incAngulo = 360 / quantidadeArcosUnicos;
                let valueByRadius = radius / (quantidadeArcosUnicos + 1);
                let currentRadius = radius;
                let angulo = 0;

                // Desenhar o cabo central (arco 999)
                if (cabosPorArco[999] !== undefined) {
                    drawArc(0, cabosPorArco[999], angulo);
                }

                // Desenhar os outros arcos
                for (let arco in cabosPorArco) {
                    if (arco !== "999") {
                        currentRadius -= valueByRadius; //raio do arco
                        angulo += incAngulo;
                        this.drawArcCircle(
                            ctx,
                            centerX,
                            centerY,
                            currentRadius
                        ); // circulo preto
                        drawArc(currentRadius, cabosPorArco[arco], angulo); //chama função dos sensores enviando o raio a quantidade de cabos e o angulo
                    }
                }
            };
            const renderArmazem = (data) => {
                ctx.fillStyle = "rgb(188,190,188)";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.fillStyle = 'white'; // Define a cor para branco
                ctx.fillRect(20, 10, 310, (canvas.height - 20)); // Desenha um retângulo branco

                const uniqueArcos = new Set();
                const cabosPorArco = {};

                // Iterar sobre o array de objetos
                data.forEach((obj) => {
                    const arco = obj.arco;
                    const cabo = obj;

                    uniqueArcos.add(arco);
                    if (!cabosPorArco[arco]) {
                        cabosPorArco[arco] = [];
                    }

                    cabosPorArco[arco].push(cabo);
                });

                const quantidadeArcosUnicos = uniqueArcos.size;
                const linhaHeight = ((canvas.height - 20)) / (quantidadeArcosUnicos + 1);
                let linhaY = linhaHeight;
                ctx.strokeStyle = 'black'; // Cor das linhas
                ctx.lineWidth = 1; // Largura das linhas

                const caboSpacingX = 320 / 10

                for (let i = 1; i <= quantidadeArcosUnicos; i++) {
                const linhaX = 20
                ctx.beginPath();
                ctx.moveTo(20, linhaY); // Comece na coordenada x=20, y=linhaY
                ctx.lineTo(330, linhaY); // Termine na coordenada x=270, y=linhaY
                ctx.stroke();

                for (let index = 0; index < cabosPorArco[i].length; index++) {
                    const cabo = cabosPorArco[i][index];
                    if (cabo.cabo >= 10) {
                        this.drawFillCircle(ctx, (linhaX + (caboSpacingX * cabo.posicao)), linhaY, 15, bluecolor);
                        this.drawText(ctx, cabo.cabo, (linhaX + (caboSpacingX * cabo.posicao) - 8), linhaY + 3);
                    } else {
                        this.drawFillCircle(ctx, (linhaX + (caboSpacingX * cabo.posicao)), linhaY, 15, bluecolor);
                        this.drawText(ctx, cabo.cabo, (linhaX + (caboSpacingX * cabo.posicao) - 5), linhaY + 3);
                    }

                }
                linhaY += linhaHeight;
            }
            };

            // Função para desenhar os arcos
            const drawArc = (radius, cabosArco) => {
                const totalCabos = cabosArco.length; //salva o total d cabos como variavel
                const anguloInicial = 70; // angulo um pouco a direita do que seria o centro do silo
                const anguloInc = -360 / totalCabos; //360 graus dividido pelo total d cabos

                for (let i = 0; i < totalCabos; i++) {
                    const angulo = anguloInicial + i * anguloInc;
                    const radians = this.degreeToRadians(angulo); // Converter graus para radianos
                    const x = Math.cos(radians) * radius + centerX; // Adicionar 175 para centralizar
                    const y = Math.sin(radians) * radius + centerY; // Adicionar 175 para centralizar

                    this.drawFillCircle(ctx, x, y, 15, bluecolor);

                    // Ajustar a posição do texto para que ele fique mais próximo do centro do círculo
                    let xText = x - 5; // Ajuste para centralizar o texto horizontalmente
                    let yText = y + 5; // Ajuste para centralizar o texto verticalmente

                    // Se o valor do cabo for maior que 9, ajuste a posição vertical
                    if (cabosArco[i] >= 10) {
                        yText += 2; // Ajuste adicional para cabos com dois dígitos
                        xText -= 5;
                    }
                    this.drawText(ctx, cabosArco[i], xText, yText);
                }
            };

            // Renderizar o silo com os dados dos cabos
            (this.silotipo == "silo" ? renderSilo(cabos) : renderArmazem(cabos))
        },
    },
};
</script>

<style scoped>
canvas {
    /* Removendo a borda preta */
    border: none;
}
.canvas-container {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

canvas {
    border: none;
    max-width: 300px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
</style>
