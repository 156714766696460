<template>
    <header class="header">
        <nav class="navbar">
            <div class="container-fluid">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <button class="menu-btn d-flex align-items-center justify-content-center p-2 bg-gray-900" id="toggle-btn">
                            <div class="group-line">
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </button>
                        <a class="navbar-brand ms-2">
                            <div class="d-flex align-items-center brand-text text-uppercase letter-spacing-0">
                                <span class="text-white fw-normal text-xs"></span>
                                <strong
                                    class="text-primary text-sm">{{ vue_app_name }}</strong>
                            </div>
                        </a>
                    </div>
                    <ul class="nav-menu mb-0 list-unstyled d-flex flex-md-row align-items-md-center">
                        <li class="nav-item margin-r">
                            <router-link class="nav-link text-branco text-sm" :to="'/v1/unidades'">Minhas Unidades</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-branco text-sm" id="usuario_nav" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Usuario: {{ this.user.nome }}
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end mt-sm-3 shadow-sm" aria-labelledby="usuario_nav">
                                <li class="dropdown-item">
                                    <router-link class="minhaconta nav-link text-sm ps-0 w-100 text-start" :to="'/minha-conta'">
                                        <i class="fa-solid fa-user"></i>
                                        &nbsp; Minha Conta
                                    </router-link>
                                </li>                              
                                <li class="dropdown-item">
                                    <button class="button-logout text-sm ps-0 w-100 text-start" @click="authLogout">
                                        <i class="fa-solid fa-right-from-bracket"></i>
                                        <span class="d-none d-sm-inline-block">&nbsp; Sair</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    setup () {
        const vue_app_name = process.env.VUE_APP_NAME;
        const version = process.env.VUE_APP_VERSION;

        return {
            vue_app_name,
            version
        }
    },
    data() {
        return {
            showDropdown: false
        };
    },
    computed: {
        ...mapGetters('auth', ['getUser']),
        user() {
            return this.getUser;
        },
    },
    methods: {
        ...mapActions('auth', ['logout']),
        async authLogout() {
            try {
                await this.logout();
                this.$router.push('/login');
            } catch (error) {
                console.error('Erro ao fazer logout:', error);
            }
        }
    }
}
</script>

<style scoped>
.margin-r {
    margin-right: 10px;
}

.text-primary {
  color: #33b35a !important;
}
.menu-btn {
    width: 4vh;
    height: 4vh;
    border-radius: 0px !important;
    border: 0px;
}
.line {
    padding: 1px;
    width: 20px;
    height: 1px;
    background-color: #fff;
    margin: 5px;
}

.text-branco {
    color: #fff;
}

.nav-link:hover {
  color: #33b35a;
}

.minhaconta:hover {
  color: black;
}

.button-logout:active {
    background-color: #33b35a;
}

.dropdown-item:hover {
    background-color: #33b35a;
}
.navbar-brand{
    align-self: center;
}
.text-primary{
    align-items: center;
}
</style>
