import axios from "@/axios";

const getDefaultState = () => ({
    unidade: []
});

const state = getDefaultState();

const mutations = {
    SET_UNIDADE(state, unidade) {
        state.unidade = unidade;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async fetchUnidade({ commit, rootState }, unidadeId) {
        try {
            const unidades = rootState.unidades.unidades;
            const unidadeEncontrada = unidades.find(u => parseInt(u.id) === parseInt(unidadeId));

            if (unidadeEncontrada) {
                commit('SET_UNIDADE', unidadeEncontrada);
            } else {
                const response = await axios.get(`/unidades/${unidadeId}`);
                const data = response.data;
                commit('SET_UNIDADE', data.unidade);
            }
			
        } catch (error) {
            console.error('Erro ao buscar unidade:', error);
            commit('SET_UNIDADE', {});
            throw error;
        }
    },

    async storaParametrosConfiguracoes(_, {unidadeId, data}) {
        try {
            const response = await axios.post(`/unidades/${unidadeId}/parametros-configuracoes`, data)
            return response.data.parametrosConfiguracoes;
        } catch (error) {
            console.error('Erro ao salvar parametros configuracoes da unidade:', error);
            throw error;
        }
    },

    async getParametrosConfiguracoes(_, unidadeId) {
        try {
            const response = await axios.get(`/unidades/${unidadeId}/parametros-configuracoes`)
            return response.data.parametrosConfiguracoes;
        } catch (error) {
            console.error('Erro ao buscar parametros configuracoes da unidade:', error);
            throw error;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
