import axios from "@/axios";

const getDefaultState = () => ({
    token: null,
    tokenExpiry: null,
    user: null,    
});

const state = getDefaultState();

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = null;
        state.tokenExpiry = null;
    },
    setUser(state, user) {
        state.user = user;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async login({ commit }, credentials) {
        try {
            const response = await axios.post("/login", credentials);
            const token = response.data.token;
            commit('setToken', token);

            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            const userResponse = await axios.get("/usuario");
            const user = userResponse.data.user;
            commit('setUser', user);
        } catch (error) {
            console.error("Erro ao fazer login:", error);
            throw new Error('Erro de autenticação: verifique suas credenciais.');
        }
    },
    logout({ commit }) {
        if (state.token) {
            axios.post("/logout").finally(() => {
                commit("clearToken");
                commit("setUser", null);
                delete axios.defaults.headers.common["Authorization"];
                commit('resetState', null, { root: true });
            });
        } else {
            commit("clearToken");
            commit("setUser", null);
            delete axios.defaults.headers.common["Authorization"];
            commit('resetState', null, { root: true });
        }
    },
};

const getters = {
    getToken(state) {
        return state.token;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    getUser(state) {
        return state.user;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
