<template>
    <div class="row">
        <div class="col-sm-8 col-w" id="termometria">
            <div class="card h-100 mb-0">
                <div class="card-header">
                    <div class="row">
                        <div class="w-50">
                            <h4 class="title-relatorio">Termometria</h4>
                        </div>
                    </div>

                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <button :class="['nav-link', 'button-component', { active: tabTermometria }]" @click="activateTabTermometria(true)" style="border-radius: 10px 10px 0px 0px !important;">Termometria</button>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div :class="['container', 'container-programa', 'tab-pane', { active: tabTermometria }]" v-if="tabTermometria">
                            <SelectLeituras :leituras="leituras" @changeLeitura="leituraChangeSelect"/>
                            <ViewLeitura :termometria="termometria"/>
                        </div>
                        <div :class="['container', 'container-programa', 'tab-pane', { active: !tabTermometria }]" v-else>
                            <GraficoMensaelTemperaturas />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4  col-w">
            <InformacoesGeraisBySilo :silo="silo" />
        </div>
        <AeracaoContent :aeracao="silo.aeracao"/>
    </div>
</template>
<script>
import SelectLeituras from './Termometria/SelectLeituras.vue';
import ViewLeitura from './Termometria/ViewLeitura.vue';
import GraficoMensaelTemperaturas from './Termometria/GraficoMensaelTemperaturas.vue';
import InformacoesGeraisBySilo from './Termometria/InformacoesGeraisBySilo.vue';
import AeracaoContent from './Termometria/AeracaoContent.vue';

import { ref } from "vue";

export default {
    props: {
        silo: Object,
        leituras: Array,
        termometria: Object
    },
    components: {
        SelectLeituras,
        ViewLeitura,
        GraficoMensaelTemperaturas,
        InformacoesGeraisBySilo,
        AeracaoContent
    },
    setup() {
        const tabTermometria = ref(true);

        return {
            tabTermometria,
            activateTabTermometria: (action) => tabTermometria.value = action,
        }
    },
    methods: {
        leituraChangeSelect(value) {
            this.$emit('selectedLeitura', value);
        }
    }
}
</script>