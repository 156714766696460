<template>
    <main>
        <slot></slot>
    </main>      
</template>

<script>
export default {
    name: 'LoginLayout'
}

</script>