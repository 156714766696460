<template>
    <div class="container-loader">
        <div class="loader"></div>
        <div class="loading-text">
            <small>Carregando informações...</small>
        </div>
    </div>
</template>

<style scoped>
.container-loader {
    z-index: 999;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff82;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #1cc910;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.loading-text {
    margin-top: 20px; /* Ajuste a margem conforme necessário */
    color: #000; /* Cor do texto */
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
  