<template>
	<UnidadeLayout>
		<nav class="navbar nav-bar-unidade">
			<div class="container-fluid">
				<a class="navbar-brand" href="#">
					{{ vue_app_name }}
				</a>
			</div>
		</nav>
		<div class="p-5">
			<div class="container header">
				<h2>Minhas unidades:</h2>
				<hr />
			</div>
			<div class="container card-unidades d-flex flex-wrap">
				<CardUnidade
					v-for="unidade in unidades"
					:key="unidade.id"
					:unidade="unidade" />
			</div>
		</div>
	</UnidadeLayout>
</template>
<script>
	import UnidadeLayout from "@/components/layouts/UnidadeLayout.vue";
	import CardUnidade from "@/components/ui/App/CardUnidade.vue";

	import { ref, onMounted } from "vue";
	import { useStore } from "vuex";
	import { useToast } from 'vue-toastification';

	export default {
		name: "UnidadesPage",
		components: {
			UnidadeLayout,
			CardUnidade,
		},
		setup() {
			const store = useStore();
			const unidades = ref([]);
			const toast = useToast();
			const vue_app_name = process.env.VUE_APP_NAME;

			onMounted(async () => {
				try {
					unidades.value = store.state.unidades.unidades;
                    if (unidades.value.length == 0) {
                        await store.dispatch("unidades/fetchUnidades");
                        unidades.value = store.state.unidades.unidades;
                    }
                    
				} catch (error) {
					console.error("Erro ao buscar unidades:", error);
					toast.error("Erro ao buscar unidades")
				}
			});

			return {
				unidades,
				vue_app_name
			};
		},
	};
</script>
<style scoped>
.nav-bar-unidade {
    box-shadow: -3px 2px 20px 0px #999191;
    background-color: #f7f7f7 !important;
}
</style>