<template>
  <div class="row container-coluna">
    <div id="div-temperaturas-show">
      <div class="header-leituras">
        <br />
        <h4 style="font-size: 0.8rem;">Informações climáticas no momento da leitura:</h4>
        <small v-if="termometria.estacao"
          ><span id="temperatura-leitura">{{ termometria.estacao.temperatura }} °C</span> -
          <span id="umidade-leitura">{{ termometria.estacao.umidade }} %</span> | Data da leitura:
          <span class="data-leitura">{{ termometria.data }} </span></small
        >
        <hr />
      </div>
      <div class="content-termometria">
        <div id="dadosSensoresColuna">
          <div class="cabo" style="border: 0px">
            <div class="sensor-temp" v-for="(sensor, index) in termometria.sensores" :key="index">S{{ (termometria.sensores - sensor) + 1 }}</div>
          </div>
        </div>
        <div id="dadosCabosColunasView">

          <div class="cabo" v-for="(cabo, index) in sortedCabos" :key="index">
            <p class="descricao">Cabo {{ cabo.cabo  }}</p>
            <div id="conteudoCabo">
              <div class="temperaturas" v-for="(sensor, index) in cabo.sensores" :key="index">
                <span v-if="cabo.temperaturas[`s${(cabo.sensores - sensor) + 1}`] < 99" class="span-umid" :style="'background-color:' + (cabo.cores ? cabo.cores[`s${(cabo.sensores - sensor) + 1}`] : '')   ">
                  {{ cabo.temperaturas[`s${(cabo.sensores - sensor) + 1}`] }} °C
                </span>
                <span v-else class="span-umid">
                  OFF
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        termometria: {
            type: Object,
            default: () => {},
        }
    },
    computed: {
        sortedCabos() {
            return Array.isArray(this.termometria.dados)
                ? this.termometria.dados.slice().sort((a, b) => a.cabo - b.cabo)
                : [];      
        }
    }
};
</script>

<style scoped>
.content-termometria {
    display: flex;
    flex-direction: row;
}
#dadosSensoresColuna {
    width: 5vh;
    padding-top: 6vh;
}
.cabo {
    display: inline-block;
    border: 2px solid #6c757d;
    border-radius: 20px;
    position: static;
    padding: 10px;
    margin-left: 5px;
    text-align: center;
}
.sensor-temp {
    background-color: green;
    color: #fff;
    width: 120%;
    margin-left: -40%;
    border-radius: 10px;
    margin-bottom: 4px;
    font-size: 17px;
}
#dadosCabosColunasView {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    height: auto;
    padding: 15px;
}
.temperaturas {
    border-radius: 10px;
    margin-bottom: 4px;
    text-align: center;
    width: 100%;
    font-size: 17px;
    color: #000;
    font-weight: 300;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
.span-umid {
    border-radius: 15px;
    width: 100%;
}
</style>