<template>
    <div class="col-sm-12 col-w">
        <br/>
        <br/>
        <div class="card">
            <div class="card-header card-header-v2">
                <h4 class="title-relatorio">Aeração</h4>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <button :class="['nav-link', 'button-component', { active: tabStatus }]" data-toggle="tab" @click="activateTabStatus(true)" style="border-radius: 10px 10px 0px 0px !important;">Status</button>
                    </li>
                </ul>
            </div>
            <div class="card-body card-body-v2">
                <div class="tab-content">
                    <div id="statusMotoresTab" class="container container-programa tab-pane active">
                        <ul class="list-group list-group-horizontal no-border list-w" v-if="aeracao && aeracao.aeracao">
                            <li class="list-group-item no-border">
                                <span>Status: &nbsp;</span>
                                <span :style="{ color: statusColor }" class="text-bolder">{{ statusMotor }}</span>
                            </li>
                            <li class="list-group-item no-border"> 
                                <span>Programa: &nbsp;</span>
                                <span class="text-bolder">{{ descricaoPrograma }}</span>
                            </li>
                            <li class="list-group-item no-border">
                                <span>Parâmetros: &nbsp;</span>
                                <span>{{ descricaoParametros }}</span>
                            </li>
                        </ul>
                        <div class="row">
                            <MotorList v-if="aeracao && aeracao.motores && aeracao.aeracao && aeracao.aeracao.data"
                                :motores="aeracao.motores" 
                                :aeracao-data="aeracao.aeracao.data" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MotorList from './MotorList.vue';

export default {
    components: {
        MotorList
    },
    props: {
        aeracao: {
            type: Object
        },
    },
    data() {
        return {
            tabStatus: true,
        }
    },
    methods: {
        activateTabStatus (action) {
            this.tabStatus = action;
        }
    },
    computed: {
        statusMotor() {
            if (this.aeracao && this.aeracao.aeracao) {
                if (this.aeracao.aeracao.modo_operacao == 0) {
                    return "Aeração no manual";
                } else if (this.aeracao.aeracao.bloqueio_aeracao == 1) {
                    return "Aeração bloqueada";
                } else {
                    return "Aeração automática";
                }
            }
            return "";
        },
        statusColor() {
            switch (this.statusMotor) {
                case "Aeração no manual":
                    return "#ff9800";
                case "Aeração bloqueada":
                    return "#ff9800";
                case "Aeração automática":
                    return "#33b35a";
                default:
                    return "#000";
            }
        },
        descricaoParametros() {
            if (this.aeracao && this.aeracao.aeracao && this.aeracao.aeracao.parametros) {
                const { temperatura_maxima, temperatura_minima, umidade_maxima, umidade_minima, horario_inicio, horario_final, media } = this.aeracao.aeracao.parametros;
                
                switch (this.aeracao.aeracao.programa) {
                    case 1:
                        return ` Temperatura Máxima: ${temperatura_maxima} °C - Temperatura Mínima: ${temperatura_minima}°C | Umidade Máxima: ${umidade_maxima}% - Umidade Mínima: ${umidade_minima}%`       
                    case 2:
                        return ` Temperatura Máxima: ${temperatura_maxima} °C - Temperatura Mínima: ${temperatura_minima}°C | Umidade Máxima: ${umidade_maxima}% - Umidade Mínima: ${umidade_minima}% | Horário de Início: ${horario_inicio} - Horário Final: ${horario_final}`       
                    case 3:
                        return ` Umidade Máxima: ${umidade_maxima}% | Umidade Mínima: ${umidade_minima}%`       
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            return `Média: ${media} °C`;
                    default:
                        return ""
                }
            }
            return "";
        },
        descricaoPrograma() {
            if (this.aeracao && this.aeracao.aeracao) {
                switch (this.aeracao.aeracao.programa) {
                    case 1:
                        return "Programa Livre"        
                    case 2:
                        return "Programa Livre Temporizado"
                    case 3:
                        return "Programa Equilibrio Higroscopico"
                    case 4:
                        return "Programa Curva 1"    
                    case 5:
                        return "Programa Curva 2"   
                    case 6:
                        return "Programa Curva 3"   
                    case 7:
                        return "Programa Curva 4"       
                    default:
                        return ""
                }
            }
            return ""
        }
    },
}
</script>
<style scoped>
.text-bolder {
    font-weight: bolder;
}
</style>