<template>
  <div class="motor-container">
    <ul class="list-group d-flex flex-wrap">
      <li
        class="list-group-item d-flex align-items-center"
        v-for="motor in motores"
        :key="motor.id"
      >
        <div class="motor-details d-flex align-items-center">
          <img
            v-if="motor.status === 1"
            src="/images/icons/ventilador2.png"
            alt="Motor Ligado"
            class="motor-icon"
          />
          <div class="motor-text">
            <h5 class="motor-title">{{ motor.descricao }}</h5>
            <span
              :class="[
                'badge',
                motor.status === 1 ? 'bg-success' : 'bg-danger',
              ]"
            >
              {{ motor.status === 1 ? "LIGADO" : "DESLIGADO" }}
            </span>
          </div>
        </div>
        <small class="text-muted ms-auto">{{ aeracaoData }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    motores: {
      type: Array,
      default: () => [],
    },
    aeracaoData: {
      type: [String, Date],
      default: null,
    },
  },
};
</script>

<style scoped>
.motor-container {
  margin-top: 1rem;
}

.list-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Espaçamento entre os itens */
  flex-direction: row;
}

.list-group-item {
  display: flex;
  align-items: center;
  flex: 0 1 calc(30% - 1rem); /* Ajusta a largura dos itens para um tamanho maior */
  max-width: 300px; /* Tamanho máximo fixo maior */
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: background-color 0.2s, box-shadow 0.2s;
  box-sizing: border-box;
  overflow: hidden; /* Previne o texto de sair do container */
}

.list-group-item:hover {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.motor-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 1rem; /* Espaço entre detalhes e data */
}

.motor-text {
  display: flex;
  flex-direction: column;
}

.motor-title {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap; /* Impede que o texto quebre em múltiplas linhas */
  overflow: hidden; /* Previne o texto de sair do container */
  text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
}

.badge {
  font-size: 0.9rem;
  padding: 0.5rem;
  margin-left: 0.5rem; /* Espaço entre a descrição e o status */
}

.bg-success {
  background-color: #28a745;
}

.bg-danger {
  background-color: #dc3545;
}

.text-muted {
  font-size: 0.9rem;
}

.motor-icon {
  width: 24px; /* Ajuste o tamanho do ícone conforme necessário */
  height: 24px;
  margin-right: 0.5rem; /* Espaço entre o ícone e o texto */
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
