<template>
    <MainLayout>
        <LoaderPage v-if="loadingPage" />
        <EstacaoComponent />
        <HeaderSilo :silo="silo" :unidadeId="unidadeId"/>
        <section class="container-content">
            <div class="container-fluid">
                <div class="row align-items-stretch py-4">
                    <div class="col-lg-12">
                        <div class="card h-100 mb-0  box-shadow-w">
                            <div class="card-header border-bottom">
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <div class="nav-link" :class="{ active: tabPrincipal }" @click="activateTabPrincipal(true)">Principal</div>
                                    </li>
                                    <li class="nav-item">
                                        <div class="nav-link" :class="{ active: !tabPrincipal }" @click="activateTabPrincipal(false)">Parâmetros</div>
                                    </li>
                                </ul>                    
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    
                                    <div id="principalTab" class="container-fluid container-programa tab-pane" :class="{ active: tabPrincipal }">
                                        <TermometriaComponent :silo="silo" :leituras="leituras" :termometria="termometria" @selectedLeitura="fetchTermometria"/>
                                    </div>

                                    <div id="ParametrosTab" class="container tab-pane" :class="{ active: !tabPrincipal }">
                                        <ParametrosComponent :silo="silo" @salvar-parametros="salvarParametrosApi" :saveParametrosLoading="parametrosStoreLoading"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import EstacaoComponent from '@/components/ui/App/EstacaoComponent.vue';
import HeaderSilo from '@/components/ui/App/HeaderSilo.vue';
import LoaderPage from '@/components/ui/LoaderPage.vue';
import TermometriaComponent from '@/components/ui/App/Silo/TermometriaComponent.vue';
import ParametrosComponent from '@/components/ui/App/Silo/ParametrosComponent.vue';

import "@/assets/css/pages/silo-page.css";

import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";


export default {
    components: {
        MainLayout,
        EstacaoComponent,
        HeaderSilo,
        LoaderPage,
        TermometriaComponent,
        ParametrosComponent
    },
    setup () {

        const store = useStore();
        const route = useRoute();
        const toast = useToast();
        const leituras = ref([]);
        const siloId = ref('');
        const termometria = ref({});
        const unidade = ref();
        const silo = ref({
            "cabos": {},
            "aeracao": {},
            "programa": {
                "descricao": ""
            },
            "produto": {
                "descricao": ""
            },
            "parametros": {}
        });
        const loadingPage = ref(true);

        const tabPrincipal = ref(true);
        const fetchSiloById = async (siloId) => {
            try {
                const response = await store.dispatch('silo/fetchSiloById', siloId);
                silo.value = response;
                leituras.value = silo.value.leituras;
                if (leituras.value.length > 0) {
                    fetchTermometria(leituras.value[0]._id)
                }
            } catch (error) {
                console.error('Error to request Silo, message:', error);
                toast.error('Erro ao obter dados do silo');
            }
        }

        const fetchTermometria = async (leituraId) => {
            try {
                const body = {
                    "siloId": siloId.value,
                    "leituraId": leituraId
                }
                const data = await store.dispatch("leituras/fetchLeituraById", body);
                termometria.value = data[0]
            } catch (error) {
                console.error("Erro ao buscar dados de termometria:", error);
                toast.error('Erro ao buscar dados de termometria');

            }
        };

        const requestDatasBySilo = async () => {
            loadingPage.value = true;
            silo.value = {};
            termometria.value = {};
            siloId.value = route.params.silo;
            await fetchSiloById(siloId.value);
            loadingPage.value = false;
        };

        onMounted(requestDatasBySilo);

        watch(() => route.params.silo, () => {
            requestDatasBySilo();
        });


        return {
            silo,
            unidade,
            leituras,
            fetchTermometria,
            termometria,
            loadingPage,
            unidadeId: route.params.unidade,
            tabPrincipal,
            activateTabPrincipal: (action) => tabPrincipal.value = action,
            requestDatasBySilo,
            toast
        };
    },
    methods: {
        async salvarParametrosApi(datas) {
            try{
                this.parametrosStoreLoading = true
                await this.$store.dispatch('silo/saveParametrosAeracao', {"data": datas.parametros, "siloId": this.silo.id})
                await this.$store.dispatch('silo/saveAeracao', {"data": datas.aeracao, "siloId": this.silo.id})
                this.requestDatasBySilo()
                this.parametrosStoreLoading = false
                this.toast.success("Parâmetros atualizados com sucesso")
            } catch (error){
                this.requestDatasBySilo()
                this.toast.error("Erro ao atualizar parâmetros: \n" + error.response.data.message)
                this.parametrosStoreLoading = false
            }
        }
    },
    data() {
        return {
            parametrosStoreLoading: false
        }
    },
}
</script>

<style scoped>
.nav-link:hover{
    cursor: pointer;
    color: black;
}
.nav-link{
    color: black;
}
.nav-link.active{
    color: #33b35a;
    font-weight: bold;
}
</style>