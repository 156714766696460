<template>
	<LoginLayout>
		<div class="container-fluid">
			<div class="row">
				<div
					class="col-sm-6 d-flex justify-content-center align-items-center"
					style="height: 100vh">
					<form @submit.prevent="authLoginSubmit" class="login-form">
						<div class="mb-3">
							<h3>{{ vue_app_name }}</h3>
						</div>
						<div class="mb-3">
							<label for="text" class="form-label"
								>Usuario:</label
							>
							<input
								v-model="usuario"
								type="text"
								class="form-control"
								id="usuario"
								name="usuario"
								:disabled="disabled"
								/>
						</div>
						<div class="mb-3">
							<label for="senha" class="form-label">Senha:</label>
							<input
								v-model="senha"
								type="password"
								class="form-control"
								id="senha"
								name="senha"
								:disabled="disabled"
								/>
						</div>
						<ButtonLoading v-if="login" />
						<button type="submit" class="btn btn-success w-100" v-else>
							Login
						</button>
						<div
							class="mb-3 d-flex justify-content-center align-items-center p-2">
							<small>Versão v{{ version }}</small>
						</div>
					</form>
				</div>
				<div class="col-sm-6">
					<img
						src="@/assets/images/post-cloud.png"
						class="login-image"
						alt="Winckieel Login" />
				</div>
			</div>
		</div>
	</LoginLayout>
</template>

<script>
	import { useStore } from "vuex";
	import { useRouter } from "vue-router";
	import { ref } from "vue";
	import { useToast } from 'vue-toastification';
	import LoginLayout from "@/components/layouts/LoginLayout.vue";
	import ButtonLoading from "@/components/ui/ButtonLoading.vue";

	export default {
		name: "LoginPage",
		components: {
			LoginLayout,
			ButtonLoading
		},
		setup() {
			const store = useStore();
			const router = useRouter();
			const usuario = ref();
			const senha = ref();
			const toast = useToast();
			const version = process.env.VUE_APP_VERSION;
			const vue_app_name = process.env.VUE_APP_NAME;
			const login = ref(false);
			const disabled = ref(false);

			const authLoginSubmit = async () => {
				login.value = true;
				disabled.value = true;

				try {
					await store.dispatch("auth/login", {
                        "usuario": usuario.value,
                        "senha": senha.value
                    });
					router.push("/v1/unidades");
					toast.success("Usuário autenticado com sucesso", {timeout: 2000})
				} catch (error) {
					login.value = false;
					disabled.value = false;
					console.error("Erro de autenticação:", error);
					toast.error("Erro de autenticação: verifique suas credenciais.")
				}
			};

			return {
				usuario,
				senha,
				authLoginSubmit,
				version,
				vue_app_name,
				login,
				disabled
			};
		},
	};
</script>

<style scoped>
	.login-image {
		max-width: 100%;
		height: auto;
		max-height: 100vh;
	}
	.btn {
		color: #fff;
		font-weight: bolder;
	}
</style>
