<template>
<MainLayout>
    <div class="bg-gray-200 text-sm">
        <div class="container-fluid">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 py-3">
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <li class="breadcrumb-item"><a class="fw-light" href="{{ route('home') }}">Winckieel</a></li>
                    <li class="breadcrumb-item"><a class="fw-light" href="{{ route('home') }}">Relatórios</a></li>
                    <li class="breadcrumb-item active fw-light" aria-current="page">Estação meteorológica </li>
                </ol>
            </nav>
        </div>
    </div>
    <header class="py-1">
        <div class="container-fluid py-2"></div>
    </header>
    <section class="pb-5 min-h-85">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header card-header-v2 display-space-3"
            style="display: flex; justify-content: space-between; padding: 14pt">
                            <h3 class="h4 mb-0">Relatório da estação meteorológica: </h3>
                        </div>
                        <div class="card-body">
                            <p class="text-sm">Selecione as informações abaixo:</p>
                            <form id="relatorio" method="post">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <label class="col-sm-12 form-label">Datas pré-definidas:</label>
                                        <div class="col-sm-12">
                                            <select class="form-select mb-3" name="data_pre_definida" v-model="data_pre_definida">
                                                <option value="null" selected>Selecione um período</option>
                                                <option value="0">Dia atual</option>
                                                <option value="1">Dia anterior</option>
                                                <option value="2">Últimos 7 dias</option>
                                                <option value="3">Últimos 15 dias</option>
                                                <option value="4">Últimos 30 dias</option>
                                                <option value="5">Mes atual</option>
                                            </select>
                                            <small style="font-size: 8pt;">* Quando selecionado alguma opção acima, as datas de início e término serão ignoradas.</small>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="form-label" for="data_inicio">Data de início: </label>
                                        <input class="form-control" id="data_inicio" name="data_inicio" type="date"
                                            v-model="dataInicio">
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="form-label" for="data_final">Data final: </label>
                                        <input class="form-control" id="data_final" name="data_final" type="date"
                                            v-model="dataFinal">
                                    </div>
                                </div>
                                <hr>
                            <button class="btn btn-primary btn-view-relatorio" id="viewRelatorio" type="button" @click="gerarRelatorio">Visualizar relatório </button>
                            &nbsp;
                            &nbsp;
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <LoadingRelatorio v-if="loadingDados" />
                <div v-if="estacaoData.length > 0" class="col-lg-12">
                    <div class="card">
                        <div class="card-body container-relatorio table-responsive" id="tableReport">
                            <div>
                            <div class="header-table">
                                <h2 class="title-pdf">Relatório da Estação Meteorológica </h2>
                                <span>Sistema de gerenciamento local</span>

                                <div style="margin: 25px; margin-bottom: 40px;">
                                    <h3 class="header-unidade">Unidade: {{unidade.nome}}</h3>
                                    <span>Intervalo de datas selecionadas: {{ dataInicio }} até {{ dataFinal }}</span>
                                    </div>
                                 </div>

                                <table class="table table-bordered">
                                    <thead>
                                        <th colspan="6" style="padding: 12px;">ESTAÇÃO METEOROLÓGICA 1</th>
                                        <tr>
                                            <th>Data</th>
                                            <th>Estação</th>
                                            <th>Temperatura</th>
                                            <th>Umidade</th>
                                            <th>Pluviômetro</th>
                                            <th>Chuva</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in estacaoData" :key="item.data">
                                            <td>{{ item.data }}</td>
                                            <td>{{ item.estacao }}</td>
                                            <td>{{ item.temperatura }}°C</td>
                                            <td>{{ item.umidade }}%</td>
                                            <td>{{ item.pluviometro }}mm</td>
                                            <td>{{ item.chuva }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</MainLayout>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useToast } from 'vue-toastification';
import MainLayout from '@/components/layouts/MainLayout.vue';
import LoadingRelatorio from '@/components/ui/App/LoadingRelatorio.vue';


export default {
    components: {
        MainLayout,
        LoadingRelatorio
    },
    setup() {
        const unidade = ref({});
        const store = useStore();
        const route = useRoute();
        const estacaoData = ref([]);
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const dataInicio = ref(formattedDate);
        const dataFinal = ref(formattedDate);
        const data_pre_definida = ref("null");
        const toast = useToast();
        const loadingDados = ref(false);


        const datasPredefinidas = (periodo) => {
            const today = new Date();
            let dataInicio;
            let dataFinal = today.toISOString().split('T')[0];

            switch (periodo) {
                case "0":
                    // dia atual
                    dataInicio = dataFinal;
                    break;
                case "1":
                    // dia anterior
                    dataInicio = new Date();
                    dataInicio.setDate(dataInicio.getDate() - 1);
                    dataInicio = dataInicio.toISOString().split('T')[0];
                    dataFinal = dataInicio;
                    break;
                case "2":
                    // ultimos 7 dias
                    dataInicio = new Date();
                    dataInicio.setDate(dataInicio.getDate() - 7);
                    dataInicio = dataInicio.toISOString().split('T')[0];
                    break;
                case "3":
                    // ultimos 15 dias
                    dataInicio = new Date();
                    dataInicio.setDate(dataInicio.getDate() - 15);
                    dataInicio = dataInicio.toISOString().split('T')[0];
                    break;
                case "4":
                    // ultimos 30 dias
                    dataInicio = new Date();
                    dataInicio.setDate(dataInicio.getDate() - 30);
                    dataInicio = dataInicio.toISOString().split('T')[0];
                    break;
                case "5":
                    // mes atual
                    dataInicio = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
                    break;
            }
            return { dataInicio, dataFinal };
        };

        const gerarRelatorio = async () => {
            loadingDados.value = true;
            let inicio;
            let fim;

            if (data_pre_definida.value !== "null") {
                const periodo = datasPredefinidas(data_pre_definida.value);
                inicio = periodo.dataInicio;
                fim = periodo.dataFinal;
            } else {
                inicio = dataInicio.value;
                fim = dataFinal.value;
            }

            if (!inicio || !fim) {
                toast.warning("Preencha os campos de datas corretamente")
                return;
            }

        try {
            const unidadeId = route.params.unidade;
            const data = await store.dispatch("estacao/fetchEstacaoDataRelatorio", {
                unidadeId,
                dataInicio: inicio,
                dataFim: fim
            });

                estacaoData.value = data.sort((a, b) => new Date(a.data) - new Date(b.data));
                loadingDados.value = false;
        } catch (error) {
            toast.error("Ocorreu um erro ao gerar o relatório")
            estacaoData.value = [];
            loadingDados.value = false;
        }
        }

        watch(() => store.state.unidade.unidade, (newUnidade) => {
                unidade.value = newUnidade;
            }, { immediate: true }
        );

        return {
            unidade,
            estacaoData,
            dataInicio,
            dataFinal,
            data_pre_definida,
            gerarRelatorio,
            loadingDados,
            LoadingRelatorio
        };
    }
}
</script>

<style scoped>
.header-table{
    width: 100%;
    text-align: center;
}
.container-relatorio{
    text-align: center;
}
.th{
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
}
.title-pdf, .header-unidade {
    word-wrap: break-word;
    text-align: center;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.3rem;
}
.btn-view-relatorio {
        background-color: #1C1C1C;
        color: white;
        border: 1px solid black;
    }

.btn-view-relatorio:hover {
    background-color: grey;
    color: #1C1C1C;
    border: 1px solid black;
}
</style>
