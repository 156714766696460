<template>
    <div class="col-sm-7"> 
        <div class="card">
            <div class="card-header card-header-v2">
                Selecione o modo de operação da Aeração
            </div>
            <div class="card-body card-body-v2">
                <div class="form-group padding-1">
                    <label for="programas">Selecione o programa para aeração:</label>
                    <br>
                    <div class="form-check-inline" v-for="programa in programas" :key="programa.id">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="programa" v-model="selectedProgramaId" :value="programa.id">
                            {{ programa.descricao }}
                        </label>
                    </div>
                </div>
                <div style="display:flex;">
                    <div class="form-group padding-1">
                        <label for="produtos">Modo de operação:</label>
                        <div>
                            <div class="form-check-inline" v-for="modo_operacao in modos_operacao" :key="modo_operacao.value">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="modo_operacao"
                                        v-model="selectedModoAeracao" :value="modo_operacao.value">
                                    {{ modo_operacao.descricao }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group padding-1">
                        <label for="aeracao_bloqueada">Aeração:</label>
                        <div>
                            <div class="form-check-inline" v-for="aeracao in aeracao_status" :key="aeracao.value">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" v-model="selectedAeracaoStatus" :value="aeracao.value">
                                    {{ aeracao.descricao }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group padding-1">
                    <label for="produtos">Produto:</label>
                    <select class="form-control" id="produtos" name="produto" style="width: 50%" v-model="selectedProdutoId">
                        <option :value="produto.id" v-for="produto in produtos" :key="produto.id">{{ produto.descricao }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        programa: {
            type: Object,
            default: () => ({})
        },
        modo_operacao: {
            type: Number,
            default: () => (null)
        },
        aeracao: {
            type: Number,
            default: () => (null)
        },
        produto: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            produtos: [
                {"descricao": "Vazio", "id": 1},
                {"descricao": "Cevada", "id": 2},
                {"descricao": "Feijão", "id": 3},
                {"descricao": "Milho", "id": 4},
                {"descricao": "Arroz", "id": 5},
                {"descricao": "Sorgo", "id": 6},
                {"descricao": "Soja", "id": 7},
                {"descricao": "Trigo", "id": 8},
                {"descricao": "Canola", "id": 9},
                {"descricao": "Azevém", "id": 10},
                {"descricao": "Linhaça", "id": 11},
                {"descricao": "Painço", "id": 12},
                {"descricao": "Alpiste", "id": 13},
                {"descricao": "Milheto", "id": 14},
                {"descricao": "Aveia", "id": 15},
                {"descricao": "Girassol", "id": 16},
                {"descricao": "Outro", "id": 17}
            ],
            programas: [
                {"descricao": "Programa Livre", "id": 1},
                {"descricao": "Programa Livre Temporizado", "id": 2},
                {"descricao": "Programa Equilíbrio Higroscópico", "id": 3},
                {"descricao": "Programa Curva 1", "id": 4},
                {"descricao": "Programa Curva 2", "id": 5},
                {"descricao": "Programa Curva 3", "id": 6},
                {"descricao": "Programa Curva 4", "id": 7},
            ],
            modos_operacao: [
                {"descricao": "Automatico", "value": 1},
                {"descricao": "Manual", "value": 0}
            ],
            aeracao_status: [
                {"descricao": "Liberado", "value": 0},
                {"descricao": "Bloqueado", "value": 1},

            ],
            selectedProdutoId: null,
            selectedProgramaId: null,
            selectedModoAeracao: null,
            selectedAeracaoStatus: null
        }
    },
    watch: {
        produto: {
            immediate: true,
            handler(newProduto) {
                if (newProduto && newProduto.id) {
                    this.selectedProdutoId = newProduto.id;
                }
            }
        },
        programa: {
            immediate: true,
            handler(newPrograma) {
                if (newPrograma && newPrograma.id) {
                    this.selectedProgramaId = newPrograma.id;
                }
            }
        },
        modo_operacao: {
            immediate: true,
            handler(newModo_operacao) {
                if (newModo_operacao == 0 || newModo_operacao == 1) {
                    this.selectedModoAeracao = newModo_operacao
                }
            }
        },
        aeracao: {
            immediate: true,
            handler(newAeracao) {
                if (newAeracao == 0 || newAeracao == 1) {
                    this.selectedAeracaoStatus = newAeracao
                }
            }
        }
    },
    methods: {
        getAeracaoAcoes() {
            return {
                "programa": this.selectedProgramaId,
                "produto": this.selectedProdutoId,
                "modo_operacao": this.selectedModoAeracao,
                "aeracao": this.selectedAeracaoStatus
            }
        }
    }
}
</script>
<style scoped>
    .card-header {
    display: flex;
    justify-content: space-between;
    padding: 14pt;
    }

    .card-body {
    padding: 16px;
    }

    .form-group {
    margin-bottom: 1rem;
    }

    .h-100 {
    height: 100%;
    }

    .mb-4 {
    margin-bottom: 1.5rem;
    }
</style>