<template>
	<router-link
        :to="'/v1/unidades/' + unidade.id"
		class="card text-center m-2"
		style="width: auto; border-radius: 10px"
        >
		<div class="card-body">
			<div class="container-image mb-3">
                <img :src="getImageUrl(unidade.imagem)"
                :alt="unidade.nome" class="image-unidade" />
                <img :src="getImageUrl(unidade.logo)"
                :alt="unidade.nome" class="logo-unidade"
                style="position: absolute; top: 10px; left: 10px; width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                />

			</div>
			<p class="card-text" style="color: green">{{ unidade.nome }}</p>
		</div>
	</router-link>
</template>

<script>
	import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			unidade: {
				type: Object,
				required: true,
			},
		},
        methods: {
            getImageUrl(imagePath) {
                return `${process.env.VUE_APP_SERVER}/storage/${imagePath}`;
            },
        },
	});
</script>

<style scoped>
	.image-unidade {
		width: 25vh;
		height: 25vh;
		border-radius: 50%;
		object-fit: cover;
	}

	.card {
        border: 0px solid;
        box-shadow: 10px 9px 7px #9d9595;
        border-radius: 15px;
	}

	.card-text {
		color: green; /* Texto verde */
	}
</style>
