<template>
    <li>
        <span>{{ descricao }}: &nbsp;</span>
        <span>{{ valor }}</span>
    </li>
</template>
<script>
export default {
    props: {
        descricao: {
            type: String,
            default: "",
        },
        valor: {
            type: String,
            default: "",
        },
    }
}
</script>